<template>
  <div>
    <router-view/>
  </div>
</template>
<script>
export default {
  methods:{
   
  },
  mounted(){
  
  },
  beforeDestroy(){
    
  }
}
</script>
<style>
body{
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
