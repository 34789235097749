<template>
    <button class="outer-box" @click="animateVideo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 294.07 294.1">
            <g id="Layer_2" data-name="Layer 2" >
                <g id="Layer_1-2" data-name="Layer 1">
                    <path class="inner-line"
                        d="M147.26,0c81.51.07,147.1,66.14,146.81,147.88a146.74,146.74,0,0,1-147,146.22C65.91,294.08-.31,227.57,0,146.34.31,66,66.79-.07,147.26,0ZM275.75,147.07c0-69.52-59-128.55-128.62-128.7C77.77,18.22,18.39,77.61,18.44,147.09S77.66,275.87,147.13,275.74,275.73,216.6,275.75,147.07Z">
                    </path>
                    <path  fill="#fff"
                        d="M131.2,90.81c17,0,34,.26,51-.12,9.94-.22,15.91,2.62,15.6,13.83-.24,8.54,3.78,9.67,10.11,4.58,3.57-2.88,6.9-6.09,10.63-8.75,3.5-2.5,6.25-8,11.55-5.29,4.33,2.24,2.95,7.34,3,11.3q.18,35.82,0,71.63c0,4.4,1.35,10.08-3.85,12.24-4.4,1.83-7.36-2.6-10.54-5.07-2.17-1.7-4.26-3.5-6.39-5.25-11.69-9.59-11.85-9.63-14.8,5.2-1.3,6.55-5.17,9.62-11.41,9.63-37.19.12-74.37.08-111.56.09-8.22,0-11.27-4.57-11.24-12.29.08-26.62.13-53.25,0-79.88,0-9.07,4.58-12,12.93-11.9C94.47,91,112.84,90.83,131.2,90.81Z" />
                </g>
            </g>
        </svg>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.4 177.4">
            <g id="videocapture" transform="translate(-11.3 -11.3)">
                <path id="outer-line" d="M125.663,81.42a18.813,18.813,0,0,0-18.793,18.793V238.027a18.813,18.813,0,0,0,18.793,18.793H263.477a18.813,18.813,0,0,0,18.793-18.793V100.213A18.813,18.813,0,0,0,263.477,81.42ZM250.949,186.053a12.527,12.527,0,0,1-19.474,10.422q-2.349-1.562-23.479-15.653v10.669a12.543,12.543,0,0,1-12.529,12.529H150.72a12.545,12.545,0,0,1-12.529-12.529V146.749A12.543,12.543,0,0,1,150.72,134.22h44.747A12.54,12.54,0,0,1,208,146.749v10.665l23.475-15.653h0a12.528,12.528,0,0,1,19.478,10.426Z" transform="translate(-94.57 -69.12)" fill="#fff" stroke="red" stroke-width="3"/>
                <path id="inner-line" d="M250.949,186.053a12.527,12.527,0,0,1-19.474,10.422q-2.349-1.562-23.479-15.653v10.669a12.543,12.543,0,0,1-12.529,12.529H150.72a12.545,12.545,0,0,1-12.529-12.529V146.749A12.543,12.543,0,0,1,150.72,134.22h44.747A12.54,12.54,0,0,1,208,146.749v10.665l23.475-15.653h0a12.528,12.528,0,0,1,19.478,10.426Z" transform="translate(-94.57 -69.12)" stroke="red" stroke-width="3"/>
            </g>
        </svg> -->
    </button>
</template>

<script>
export default {
    name: "VideoCapture",
    props:{
        dataURL: String
    },
    methods: {
        animateVideo(){
            const vsvg = document.querySelector('.inner-line');
            vsvg.classList.toggle('record');

            this.$root.$emit("captureVideo", true);
        }
    }
}
</script>

<style scoped>
.outer-box{
    cursor: pointer;
    /* top: 84.5vh; */
    top: calc(var(--vh, 1vh) * 83);
    left: 45vw;
    position: absolute;
    width: 65px;
    height: 65px;
    z-index: 10000;
    outline: none;
    border: none;
    overflow: hidden;
    background-color: transparent;
    background-size: contain;
    background-position: center center;
    background-origin: border-box;
}
.outer-box:focus{
    outline: none;
}
.inner-line{
    fill: #fff;
}

.inner-line.record{
    animation: recordAnim 1s ease-in-out infinite;
}

@keyframes recordAnim {
    0%, 100%{
        fill: #fff;
    }
    50%{
        fill: #ff0000;
    }
}
</style>