<template>
    <button class="outer-circle" @click="animateImage">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302.46 302.27">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path class="fill_color"
                        d="M302.46,151.46C301.17,236,234.93,302.37,150.9,302.27,68.09,302.17,0,237.44,0,151.11,0,65.25,67.38-.24,151.64,0,236.4.24,301.48,67.13,302.46,151.46Zm-17.84-.3C284.34,80.45,229,18.22,151.86,17.71c-74.5-.5-134.11,57.77-134.2,133.34-.09,76.53,61,132.88,132.68,133.51C224.15,285.21,284.56,224.72,284.62,151.16Z" />
                    <path class="fill_color"
                        d="M150.44,201.43q-37.45,0-74.9,0c-10.56,0-13.58-3-13.6-13.55q0-41,0-82c0-9,2.2-11.45,10.92-12.43,2.17-.24,4.51-.15,5.81-2.1,6.8-10.17,17.08-5.68,26.12-6.25a4.75,4.75,0,0,1,3.6,2c4.2,6.93,10.81,6.43,17.58,6.4,33.05-.13,66.1-.08,99.15,0,12.17,0,14.73,2.56,14.74,14.55q.06,39.58,0,79.18c0,11.11-3.28,14.3-14.54,14.31Q187.89,201.46,150.44,201.43Zm-39.19-48.38c0,22.24,17.65,40.19,39.55,40.17,21.71,0,39.75-18.31,39.69-40.22s-18.4-40-40.1-39.86C129,113.28,111.27,131.4,111.25,153.05Zm100.8-39.71c2.61,0,5.24-.12,7.84,0,4,.23,6.2-.91,6.22-5.52s-1.37-6.61-6.14-6.36c-5,.26-10,.21-15,0-4.31-.17-6.59,1-6.6,5.92s2.15,6.28,6.52,6C207.28,113.2,209.67,113.34,212.05,113.34Z" />
                    <path class="fill_color"
                        d="M150.48,187.06a34.4,34.4,0,0,1-34.09-34.8c.11-18.76,16-34.48,34.75-34.37a34.63,34.63,0,0,1,34.39,35.37C185.28,172.3,169.8,187.22,150.48,187.06Zm.42-11.95c12.89,0,22.6-9.55,22.7-22.35S164,130,151.4,129.88c-13.26-.07-23.05,9.58-23.12,22.77A22.22,22.22,0,0,0,150.9,175.11Z" />
                </g>
            </g>
        </svg>
    </button>
</template>

<script>
export default {
    name: "ImageCapture",
    props:{
        dataURL: String
    },
    methods: {
        animateImage(){
            // const isvg = document.querySelector('.outer-circle');
            // isvg.classList.add('capture');
            // setTimeout(()=>{
            //     isvg.classList.remove('capture');
            // }, 200);

            this.$root.$emit("captureImage", true);
        }
    }
}
</script>
4826,09
<style scoped>
.outer-circle{
    cursor: pointer;
    /* top: 85vh; */
    top: calc(var(--vh, 1vh) * 83);
    left: 15vw;
    position: absolute;
    width: 65px;
    height: 65px;
    background-color: transparent;
    background-size: contain;
    background-position: center center;
    background-origin: border-box;
    z-index: 10000;
    outline: none;
    border: none;
}
.fill_color{
    fill: #fff;
}
.outer-circle.capture{
    animation: capture 0.3s ease 1;
}
@keyframes capture {
    0%, 100%{
        transform: scale(1);
    }
    50%{
        transform: scale(0.7);
    }
}
</style>