<template>
    <div class="loadingContainer">
        <img class="loadingLogo" src="../../public/UiAssets/svg-gif.gif">
    </div>
</template>

<script>
export default {
    name: "LoadingIcon",
}
</script>

<style scoped>
    .loadingContainer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        /* background-color: rgb(100, 100, 100); */
        opacity: 0.75;
        z-index: 500;
    }
    .loadingLogo{
        position: absolute;
        /* top: 25vh; */
        top: calc(var(--vh, 1vh) * 35);
        left: 18vw;
        width: 65vw;
        height: auto;
    }
</style>