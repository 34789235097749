<template>
    <div class="captureModalContainer">
        <button @click="close" id="close_bt">
            <img src="../../public/UiAssets/back.svg">
        </button>
        
        <div ref="videoPreviewModal" class="previewContainer">
            <!-- <img v-if="content.type == 'image'" :src="content.url" class="contentStyle">
            <video ref="videoPlayback" v-if="content.type == 'video'" crossorigin="anonymous" controls class="contentStyle" onloadstart="this.volume=0.3" playsinline>
                <source :src="content.url">
            </video> -->
        </div>
        <button @click="save" id="save_bt">
            <img src="../../public/UiAssets/save.svg">
        </button>
        
        <!-- <button @click="save"></button> -->
    </div>
</template>
<script>
export default {
    name: "CaptureModal",
    props:[
        "content"
    ],
    methods:{
        close(){
            this.$root.$emit("closePreview");
        },
        save(){
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';

            a.href = this.content.url;
            a.download = this.content.time;
            a.click();
            a.remove();
        },
        createVideo(){
            let container = this.$refs["videoPreviewModal"];

            let videoEl = document.createElement('video');
            // video style
            videoEl.style.width = "75vw";
            videoEl.style.borderRadius = "20px";
            // video attributes
            videoEl.WebKitPlaysInline = true;
            videoEl.crossOrigin = 'anonymous';
            videoEl.loop = true;
            videoEl.setAttribute("controls","controls")   
            videoEl.setAttribute("playsinline", null)
            videoEl.type = "video/mp4";
            if(this.content.ios){
               videoEl.muted = true;
            }

            let source = document.createElement('source');
            source.src = this.content.url;
            videoEl.appendChild(source);

            container.appendChild(videoEl)
        },
        createImage(){
            let container = this.$refs["videoPreviewModal"];

            let imgEl = document.createElement('img');
            // video style
            imgEl.style.width = "75vw";
            imgEl.style.borderRadius = "20px";
            // image attributes
            imgEl.src = this.content.url;
            container.appendChild(imgEl)
        }
    },
    watch:{
        content(newVal){
            // let container = this.$refs["videoPreviewModal"];

            // while (container.firstChild) {
            //     container.removeChild(container.lastChild);
            // }
            if(newVal.type == "video"){
                this.createVideo();
            }else if(newVal.type == "image"){
                this.createImage();
            }
        }
    }
}
</script>
<style scoped>
    .captureModalContainer{
        position: absolute;
        /* top: 5vh;
        left: 10vw; */
        /* width: 80vw;
        height: 80vh; */
        top: 0vh;
        left: 0vw;
        width: 100vw;
        height: calc(var(--vh, 1vh) * 100);
        /* max-height: 99.5vh; */
        background-color: white;
        z-index: 900;

        /* border-radius: 20px; */

        display: grid;
        align-items: center;
        justify-items: center;
        /* place-items: center center; */
    }
    /* .contentStyle{
        width: 75vw;
        height: 65vh;
        border-radius: 20px;
    } */
    #close_bt{
        width: 25vw;
        height: auto;
        background-color: transparent;
        border: none;
    }
    #save_bt{
        width: 25vw;
        height: auto;
        margin-bottom: 25px;
        background-color: transparent;
        border: none;
        align-self: start;
    }
</style>