<template>
    <div>
        <div>
          <video
            loop
            autoplay
            muted
            playsinline
            id="videoOrb">
          </video>
          <canvas id="canvasOrb"></canvas>
        </div>
        <img class="searchingLogoORB" src="UiAssets/searchingMulti.svg" v-if="!orbDetectedMarker">
        <ArComponent :contentArr="orbFoundArr" :mediaStreamProp="mediaStream" :loadType="loadType" :videoProp="video" :canvasProp="canvas" v-if="loadedVideo" v-on:close="close" :selectExperience="selectExperience"></ArComponent>
    </div>
</template>
<script>
import ArComponent from './ArComponent.vue';
import jsfeat from 'jsfeat';

export default {
  name:"Orb",
  components:{
    ArComponent,
  },
  props:[
    'contentArr',
    'loadType',
    'selectExperience',
  ],
  data(){
    return{
        video: null,
        canvas: null,
        ctx: null,
        canvas_process: null,
        context_process: null,
        input_width: null,
        input_height: null,
        loadedVideo: false,
        mediaStream: null,

        //ORB VARIABLES 
        img_u8: null,
        img_u8_smooth: null,
        screen_descriptors: [],
        screen_corners: [],
        loadedPattern: [],
        loadedCorners: [],
        matches: [],
        homo3x3: null,
        match_mask: null,
        options:{
          blur_size: 5,
          num_train_levels: 5,
          lap_thres: 20,
          eigen_thres: 10,
          match_threshold: 48,
        },

        n_matches: 0,
        g_matches:0,

        loadedOrbMarkers: [],
        orbFoundArr: [],
        orbMarkerFound: null,
        orbDetectedMarker: false,

        counter: 0,
        counterBool: false,

        match_t: (function () {
            function match_t(screen_idx, pattern_lev, pattern_idx, distance) {
                if (typeof screen_idx === "undefined") { screen_idx=0; }
                if (typeof pattern_lev === "undefined") { pattern_lev=0; }
                if (typeof pattern_idx === "undefined") { pattern_idx=0; }
                if (typeof distance === "undefined") { distance=0; }

                this.screen_idx = screen_idx;
                this.pattern_lev = pattern_lev;
                this.pattern_idx = pattern_idx;
                this.distance = distance;
            }
            return match_t;
        })(),
    }
  },
  methods:{
    // INIT ORB
    async initCamera(){
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

        let hint = {
            audio: false,
            video: {
              width: { ideal: 1024},
              height: { ideal: 540 },
              facingMode: {ideal: "environment"}
            },
        };
        
        let _this = this;

        this.mediaStream = navigator.mediaDevices.getUserMedia(hint).then( stream => {
          _this.video.srcObject = stream;
          _this.video.captureStream = _this.video.captureStream || _this.video.mozCaptureStream;
          _this.video.addEventListener("loadedmetadata", function() {
            _this.video.play();
            console.log(_this.video.videoWidth, _this.video.videoHeight)
            _this.inputWidth = _this.video.videoWidth;
            _this.inputHeight = _this.video.videoHeight;
          });

          _this.video.addEventListener("playing", function(){
            _this.initMatching();
            _this.loadedVideo = true;
          });
        })
      }
    },
    initMatching(){
      this.vw = this.inputWidth;
      this.vh = this.inputHeight;

      this.pscale = 320 / Math.max(this.vw, this.vh / 3 * 4);
      this.sscale = window.outerWidth / this.inputWidth;

      this.sw = this.vw * this.sscale;
      this.sh = this.vh * this.sscale;

      this.w = this.vw * this.pscale;
      this.h = this.vh * this.pscale;
      this.pw = Math.max(this.w, this.h / 3 * 4);
      this.ph = Math.max(this.h, this.w / 4 * 3);
      this.ox = (this.pw - this.w) / 2;
      this.oy = (this.ph - this.h) / 2;

      // this.input_width = this.pw;
      // this.input_height = this.ph;
      // this.canvas_process.width = this.pw;
      // this.canvas_process.height = this.ph;
      this.input_width = this.inputWidth;
      this.input_height = this.inputHeight;
      this.canvas_process.width = this.inputWidth;
      this.canvas_process.height = this.inputHeight;

      this.img_u8 = new jsfeat.matrix_t(this.input_width, this.input_height, jsfeat.U8_t | jsfeat.C1_t);
      // after blur
      this.img_u8_smooth = new jsfeat.matrix_t(this.input_width, this.input_height, jsfeat.U8_t | jsfeat.C1_t);
      this.screen_descriptors = new jsfeat.matrix_t(32, 500, jsfeat.U8_t | jsfeat.C1_t);

      var i = this.input_width * this.input_height;

      while(--i >= 0) {
        this.screen_corners[i] = new jsfeat.keypoint_t(0,0,0,0,-1);
        this.matches[i] = new this.match_t();
      }

      this.homo3x3 = new jsfeat.matrix_t(3,3,jsfeat.F32C1_t);
      this.match_mask = new jsfeat.matrix_t(500,1,jsfeat.U8C1_t);

      this.tickOrb();
    },
    // PROCESS ORB
    tickOrb(){
      if(!this.orbDetectedMarker){
        requestAnimationFrame(this.tickOrb);
       
        this.context_process.drawImage(this.video, 0, 0, this.input_width, this.input_height);
        var imageData = this.context_process.getImageData(0, 0, this.input_width, this.input_height);

        jsfeat.imgproc.grayscale(imageData.data, this.input_width, this.input_height, this.img_u8);
        
        jsfeat.imgproc.gaussian_blur(this.img_u8, this.img_u8_smooth, this.options.blur_size|0);

        jsfeat.yape06.laplacian_threshold = this.options.lap_thres|0;
        jsfeat.yape06.min_eigen_value_threshold = this.options.eigen_thres|0;

        this.num_corners = this.detect_keypoints(this.img_u8_smooth, this.screen_corners, 500);

        jsfeat.orb.describe(this.img_u8_smooth, this.screen_corners, this.num_corners, this.screen_descriptors);

        // render pattern and matches
        for(let i = 0; i < this.loadedOrbMarkers.length; i++){
            let num_matches = 0;
            let good_matches = 0;
            num_matches = this.match_pattern(i);
            good_matches = this.find_transform(this.matches, num_matches, i);

            this.n_matches = num_matches;
            this.g_matches = good_matches;
            if(num_matches) {
              if(good_matches > 8){
                // this.render_pattern_shape(this.context_process, i);
                this.orbFoundArr = [this.contentArr[i]];
                this.orbMarkerFound = i;
                this.orbDetectedMarker = true;
              }
            }else{
              this.orbMarkerFound = null;
            }
        }
      }
    },
    detect_keypoints(img, corners, max_allowed) {
      // detect features
      var count = jsfeat.yape06.detect(img, corners, 17);

      // sort by score and reduce the count if needed
      if(count > max_allowed) {
          jsfeat.math.qsort(corners, 0, count-1, function(a,b){return (b.score<a.score);});
          count = max_allowed;
      }

      // calculate dominant orientation for each keypoint
      for(var i = 0; i < count; ++i) {
          corners[i].angle = this.ic_angle(img, corners[i].x, corners[i].y);
      }

      return count;
    },
    find_transform(matches, count, index) {
      // motion kernel
      var mm_kernel = new jsfeat.motion_model.homography2d();
      // ransac params
      var num_model_points = 4;
      var reproj_threshold = 3;
      var ransac_param = new jsfeat.ransac_params_t(num_model_points, reproj_threshold, 0.5, 0.99);

      var pattern_xy = [];
      var screen_xy = [];

      // construct correspondences
      for(var i = 0; i < count; ++i) {
        var m = matches[i];
        var s_kp = this.screen_corners[m.screen_idx];
        var p_kp = this.loadedOrbMarkers[index].loadedCorners[m.pattern_lev][m.pattern_idx];
        pattern_xy[i] = {"x":p_kp.x, "y":p_kp.y};
        screen_xy[i] =  {"x":s_kp.x, "y":s_kp.y};
      }

      // estimate motion
      var ok = false;
      ok = jsfeat.motion_estimator.ransac(ransac_param, mm_kernel, pattern_xy, screen_xy, count, this.homo3x3, this.match_mask, 1000);

      // extract good matches and re-estimate
      var good_cnt = 0;
      if(ok) {
        for(var i_1 = 0; i_1 < count; ++i_1) {
          if(this.match_mask.data[i_1]) {
            pattern_xy[good_cnt].x = pattern_xy[i_1].x;
            pattern_xy[good_cnt].y = pattern_xy[i_1].y;
            screen_xy[good_cnt].x = screen_xy[i_1].x;
            screen_xy[good_cnt].y = screen_xy[i_1].y;
            good_cnt++;
          }
        }
        // run kernel directly with inliers only
        mm_kernel.run(pattern_xy, screen_xy, this.homo3x3, good_cnt);
      } else {
        jsfeat.matmath.identity_3x3(this.homo3x3, 1.0);
      }

      return good_cnt;
    },
    match_pattern(index) {
      var q_cnt = this.screen_descriptors.rows;
      // var query_du8 = this.screen_descriptors.data;
      var query_u32 = this.screen_descriptors.buffer.i32; // cast to integer buffer
      var qd_off = 0;
      var qidx=0,lev=0,pidx=0,k=0;
      var num_matches = 0;

      for(qidx = 0; qidx < q_cnt; ++qidx) {
        var best_dist = 256;
        var best_dist2 = 256;
        var best_idx = -1;
        var best_lev = -1;

        for(lev = 0; lev < this.options.num_train_levels; ++lev) {
            var lev_descr = this.loadedOrbMarkers[index].loadedPattern[lev];
            var ld_cnt = lev_descr.rows;
            var ld_i32 = lev_descr.buffer.i32; // cast to integer buffer
            var ld_off = 0;
            for(pidx = 0; pidx < ld_cnt; ++pidx) {
              var curr_d = 0;
              // our descriptor is 32 bytes so we have 8 Integers
              for(k=0; k < 8; ++k) {
                curr_d += this.popcnt32( query_u32[qd_off+k]^ld_i32[ld_off+k] );
              }

              if(curr_d < best_dist) {
                best_dist2 = best_dist;
                best_dist = curr_d;
                best_lev = lev;
                best_idx = pidx;
              } else if(curr_d < best_dist2) {
                best_dist2 = curr_d;
              }

              ld_off += 8; // next descriptor
            }
          }
          // console.log(best_dist)
          // filter out by some threshold
          if(best_dist < this.options.match_threshold) {
            this.matches[num_matches].screen_idx = qidx;
            this.matches[num_matches].pattern_lev = best_lev;
            this.matches[num_matches].pattern_idx = best_idx;
            num_matches++;
          }
          //

          /* filter using the ratio between 2 closest matches
          if(best_dist < 0.8*best_dist2) {
              matches[num_matches].screen_idx = qidx;
              matches[num_matches].pattern_lev = best_lev;
              matches[num_matches].pattern_idx = best_idx;
              num_matches++;
          }
          */

          qd_off += 8; // next query descriptor
      }

      return num_matches;
    },
    render_pattern_shape(ctx, index) {
      // get the projected pattern corners
      var shape_pts = this.tCorners(this.homo3x3.data, this.loadedOrbMarkers[index].loadedPattern[0].cols*2, this.loadedOrbMarkers[index].loadedPattern[0].rows*2);

      ctx.strokeStyle = "rgb(0,255,0)";
      ctx.beginPath();

      ctx.moveTo(shape_pts[0].x,shape_pts[0].y);
      ctx.lineTo(shape_pts[1].x,shape_pts[1].y);
      ctx.lineTo(shape_pts[2].x,shape_pts[2].y);
      ctx.lineTo(shape_pts[3].x,shape_pts[3].y);
      ctx.lineTo(shape_pts[0].x,shape_pts[0].y);

      ctx.lineWidth=4;
      ctx.stroke();
    },
    render_corners(corners, count, img, step) {
      var pix = (0xff << 24) | (0x00 << 16) | (0xff << 8) | 0x00;
      for(var i=0; i < count; ++i)
      {
        var x = corners[i].x;
        var y = corners[i].y;
        var off = (x + y * step);
        img[off] = pix;
        img[off-1] = pix;
        img[off+1] = pix;
        img[off-step] = pix;
        img[off+step] = pix;
      }
    },
    // ORB LOADER
    async loadMarkers(){
      let _this = this;
      for(let i = 0; i < this.contentArr.length; i++){
        await this.makeRequest({
          method: 'GET',
          url: this.contentArr[i].orbUrl
        })
        .then(function (response) {
          _this.parseMarker(response, i);
        })
        .catch(function (err) {
          console.error('Augh, there was an error!', err);
        });
      }
      // console.log(this.loadedOrbMarkers)
      this.initCamera();
    },
    parseMarker(txt, index){
      this.loadedOrbMarkers[index] = {
        loadedPattern: [],
        loadedCorners: []
      }

      let lines = txt.split("\n");
      let corner_headers = lines[0].substring(1);
      let corner_headers_indexes = corner_headers.split(";");
      corner_headers_indexes.pop();
      for(let i in corner_headers_indexes){
        let indexes = corner_headers_indexes[i].split("-");
        corner_headers_indexes[i] = { b: parseInt(indexes[0]) - 1, e: parseInt(indexes[1]) -2}
      }
      
      for(let i in corner_headers_indexes){
        this.loadedOrbMarkers[index].loadedCorners[i] = [];
        let obj = corner_headers_indexes[i];
        for(let j = obj.b; j < obj.e; j++){
          let line_arr = lines[j].split(";");
          line_arr.pop();
          this.loadedOrbMarkers[index].loadedCorners[i].push({ x:parseFloat(line_arr[0]), y: parseFloat(line_arr[1]), score: parseInt(line_arr[2]), level: parseInt(line_arr[3]), angle:parseFloat(line_arr[4])});
        }
        let zero_amount = parseInt(lines[obj.e + 1].substring(1));

        for(let j = 0; j < zero_amount; j++){
          this.loadedOrbMarkers[index].loadedCorners[i].push({ x:0, y: 0, score: 0, level: 0, angle: -1});
        }
      }

      let descriptor_headers = lines[1].substring(1);
      let descriptor_headers_indexes = descriptor_headers.split(";");
      descriptor_headers_indexes.pop();
      for(let i in descriptor_headers_indexes){
        let indexes = descriptor_headers_indexes[i].split("-");
        descriptor_headers_indexes[i] = { b: parseInt(indexes[0]), e: parseInt(indexes[1])}
      }

      for(let i in descriptor_headers_indexes){
        let matrix_attributes = lines[descriptor_headers_indexes[i].b - 1].split(";");
        matrix_attributes.pop();
        let string_buffer = "";
        for(let j = descriptor_headers_indexes[i].b; j < descriptor_headers_indexes[i].e; j++){
          string_buffer += lines[j];
        }
    
        let uIntArr = new Uint8Array(string_buffer.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
        let buffer = this.typedArrayToBuffer(uIntArr);
        let matrix = {
          buffer: {
            buffer,
            f32: new Float32Array(buffer),
            f64: new Float64Array(buffer),
            i32: new Int32Array(buffer),
            u8: new Uint8Array(buffer),
            size: buffer.byteLength,
          },
          cols: parseInt(matrix_attributes[0]),
          rows: parseInt(matrix_attributes[1]),
          type: parseInt(matrix_attributes[2]),
          channel: parseInt(matrix_attributes[3]),
          data: new Uint8Array(buffer)
        }
        this.loadedOrbMarkers[index].loadedPattern.push(matrix);
      }

      // console.log(this.loadedCorners, this.loadedPattern)
    },
    // ORB UTIL
    ic_angle(img, px, py) {
        var u_max = new Int32Array([15,15,15,15,14,14,14,13,13,12,11,10,9,8,6,3,0]);
        var half_k = 15; // half patch size
        var m_01 = 0, m_10 = 0;
        var src=img.data, step=img.cols;
        var u=0, v=0, center_off=(py*step + px)|0;
        var v_sum=0,d=0,val_plus=0,val_minus=0;

        // Treat the center line differently, v=0
        for (u = -half_k; u <= half_k; ++u)
            m_10 += u * src[center_off+u];

        // Go line by line in the circular patch
        for (v = 1; v <= half_k; ++v) {
            // Proceed over the two lines
            v_sum = 0;
            d = u_max[v];
            for (u = -d; u <= d; ++u) {
                val_plus = src[center_off+u+v*step];
                val_minus = src[center_off+u-v*step];
                v_sum += (val_plus - val_minus);
                m_10 += u * (val_plus + val_minus);
            }
            m_01 += v * v_sum;
        }

        return Math.atan2(m_01, m_10);
    },
    typedArrayToBuffer(array){
      return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset)
    },
    tCorners(M, w, h) {
      var pt = [ {'x':0,'y':0}, {'x':w,'y':0}, {'x':w,'y':h}, {'x':0,'y':h} ];
      var z=0.0, i=0, px=0.0, py=0.0;

      for (; i < 4; ++i) {
        px = M[0]*pt[i].x + M[1]*pt[i].y + M[2];
        py = M[3]*pt[i].x + M[4]*pt[i].y + M[5];
        z = M[6]*pt[i].x + M[7]*pt[i].y + M[8];
        pt[i].x = px/z;
        pt[i].y = py/z;
      }

      return pt;
    },
    popcnt32(n) {
      n -= ((n >> 1) & 0x55555555);
      n = (n & 0x33333333) + ((n >> 2) & 0x33333333);
      return (((n + (n >> 4))& 0xF0F0F0F)* 0x1010101) >> 24;
    },
    makeRequest(opts) {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open(opts.method, opts.url);
        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            resolve(xhr.response);
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          }
        };
        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        };
        xhr.send(null);
      });
    },
    close(){
      this.orbFoundArr = [];
      this.orbMarkerFound = null;
      this.orbDetectedMarker = false;
      setTimeout(()=>{ this.tickOrb(); },500)
    },
    onWindowResize(){
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
    }
  },
  mounted(){
    this.video = document.getElementById('videoOrb');
    this.canvas = document.getElementById('canvasOrb');

    this.canvas_process = document.createElement('canvas');
    this.context_process = this.canvas_process.getContext('2d');

    window.addEventListener( 'resize', this.onWindowResize, false );

    // setTimeout(()=>{
    //    console.log("video", this.video.videoWidth, this.video.videoHeight)
    //     console.log("canvas size", this.canvas_process.width, this.canvas_process.height)
    //     console.log("canvas_context", this.context_process)
    //     console.log("native sizes", this.inputWidth, this.inputHeight)
    //     console.log("input sizes", this.input_width, this.input_height)
    //     console.log("rescaled", this.pw, this.ph)
    // }, 5000)

    this.loadMarkers();
  },
  beforeDestroy(){
    window.removeEventListener( 'resize', this.onWindowResize, false );
  }
}
</script>
<style scoped>
  #videoOrb{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  #canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    display: block;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  #canvasOrb{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    display: block;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  .tickButton{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  .searchingLogoORB{
    position: absolute;
    top: calc(100vh - (70vh));
    left: calc(100vw - (75vw));
    width: 50vw;
    height: auto;
    z-index: 1000;
  }
</style>