import * as THREE from 'three';

export class LowPassFilter {
    constructor(alpha) {
      this.setAlpha(alpha);
      this.y = null;
      this.s = null;
    }
    
    setAlpha(alpha) {
      if (alpha <= 0 || alpha > 1.0) {
        throw new Error();
      }
      this.alpha = alpha;
    }
    
    filter(value, timestamp, alpha) {
      if (alpha) {
        this.setAlpha(alpha);
      }
      let s;
      if (!this.y) {
        s = value;
      } else {
        s = this.alpha * value + ( 1.0 - this.alpha ) * this.s;
      }
      this.y = value;
      this.s = s;
      return s;
    }
    
    lastValue() {
      return this.y;
    }
  }
  
export class OneEuroFilter {
    constructor(freq, minCutOff=1.0, beta=0.0, dCutOff=1.0) {
      if (freq <= 0 || minCutOff <= 0 || dCutOff <= 0) {
          throw new Error();
      }
      this.freq = freq;
      this.minCutOff = minCutOff;
      this.beta = beta;
      this.dCutOff = dCutOff;
      this.x = new LowPassFilter(this.alpha(this.minCutOff));
      this.dx = new LowPassFilter(this.alpha(this.dCutOff));
      this.lasttime = null;
    }

    alpha(cutOff) {
      const te = 1.0 / this.freq;
      const tau = 1.0 / ( 2 * Math.PI * cutOff );
      return 1.0 / ( 1.0 + tau / te );
    }

    UpdateParams(_freq, _mincutoff = 1.0, _beta = 0, _dcutoff = 1){
      this.freq = _freq;
      this.minCutOff = _mincutoff;
      this.beta = _beta;
      this.dCutOff = _dcutoff;
      this.x.setAlpha(this.alpha(this.minCutOff));
      this.dx.setAlpha(this.alpha(this.dCutOff));
    }

    Filter(x, timestamp=null) {
      if (this.lasttime && timestamp) {
        this.freq = 1.0 / ( timestamp - this.lasttime );
      }
      this.lasttime = timestamp;
      const prevX = this.x.lastValue();
      const dx = (!prevX) ? 0.0 : ( x - prevX ) * this.freq;
      const edx = this.dx.filter(dx, timestamp, this.alpha(this.dCutOff));
      const cutOff = this.minCutOff + this.beta * Math.abs(edx);
      return this.x.filter(x, timestamp, this.alpha(cutOff));
    }
}

export class OneEuroFilterVector3 {
  constructor(freq, minCutOff = 1.0, beta = 0.0, dCutOff = 1.0) {
    if (freq <= 0 || minCutOff <= 0 || dCutOff <= 0) {
      throw new Error();
    }
    this.currValue = new THREE.Vector3();
    this.prevValue = new THREE.Vector3();

    this.freq = freq;
    this.minCutOff = minCutOff;
    this.beta = beta;
    this.dCutOff = dCutOff;

    this.oneEuroFilters = [];
    this.oneEuroFilters.push(new OneEuroFilter(freq, minCutOff, beta, dCutOff))
    this.oneEuroFilters.push(new OneEuroFilter(freq, minCutOff, beta, dCutOff))
    this.oneEuroFilters.push(new OneEuroFilter(freq, minCutOff, beta, dCutOff))
  }

  UpdateParams(freq, mincutoff = 1.0, beta = 0, dcutoff = 1){
    this.freq = freq;
    this.mincutoff = mincutoff;
    this.beta = beta;
    this.dcutoff = dcutoff;

    for (let i = 0; i < this.oneEuroFilters.length; i++){
      this.oneEuroFilters[i].UpdateParams(this.freq, this.mincutoff, this.beta, this.dcutoff);
    }    
  }

  Filter(value, timestamp = -1.0) {
    this.prevValue.copy(this.currValue);

    let output = [0,0,0];

    let input = [value.x, value.y, value.z];

    this.oneEuroFilters.forEach((filters, idx) => {
      output[idx] = filters.Filter(input[idx], timestamp);
    })

    return this.currValue = new THREE.Vector3(output[0], output[1], output[2]);
  }
}