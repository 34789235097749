<template>
    <div class="searchingContainer">
        <div class="pos_container">
            <img class="searchingLogo" :src="icon">
            <div id="scan_text_container">
                <p id="scan_text">Scan image to view AR experience</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SearchingIcon",
    props:[
        "icon",
        "scan_text"
    ],
    data(){
        return {
            // icons: ["./Ui_Assets/searchingBorder.png"]
            // icons: ["./Ui_Assets/searchingNFT.svg"]
        }
    },
    
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
    .searchingContainer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        /* height: 100vh; */
        height: calc(var(--vh, 1vh) * 100);
        z-index: 500;
    }
    .searchingLogo{
        width: 50vw;
        opacity: 0.55;
        height: auto;
    }
    .pos_container{
        /* top: calc(var(--vh, 1vh) * 25); */
        /* left: 27vw; */
        display: flex;
        width: 100vw;
        height: calc(var(--vh, 1vh) * 100);
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    #scan_text_container{
        
        text-align: center;
    }
    #scan_text{
        padding: 6px;
        font-size: 15px;
        vertical-align: middle;
        background-color: rgb(92, 87, 92);
        border: none;
        border-radius: 10px;
        font-family: 'Roboto', sans-serif;
        color: white;
        font-weight: bold;
    }
</style>