<template>
    <div class="modal_container">
        <div class="link_container">
            <label id="link_label">Open link</label>
            <a :href="link" id="link_button" target="_blank">Open</a>
        </div>
    </div>
</template>

<script>
export default {
    name:"PopUpModal",
    props:[
        "link"
    ]
}
</script>
<style scoped>
.modal_container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.link_container{
    position: absolute;
    top: 30vh;
    left: 40vw;
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    background-color: white;
    border-radius: 10px;
    border-style: solid;
    border-color: black;
    font-size: 25px;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
}
#link_button{
    color: #42b983;
    width: 75px;
    height: auto;
    /* background-color: green; */
    text-decoration: none;
    border-color: #42b983;
    border-radius: 10px;
    border-style: solid;
    margin-top: 10px;
    padding: 5px;
}
#link_button:hover{
    background-color: #8dd5b4;
    color: white;
}
</style>