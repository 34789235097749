<script src="https://www.googletagmanager.com/gtm.js?id=GTM-KXX5LV6"></script>
<template>
    <div id="exp_container">
        <div id="topbar">
            <img id="topborder" src="../../public/UiAssets/topbar.svg">
            <img id="logo" src="../../public/UiAssets/logo.svg">
        </div>
        <div id="exp_flex">
            <div id="bg_container">
                <p id="notify">Please choose how would you like to view the AR experience and grant required accesses</p>                
                <div id="FbContainner">
                    <p class="exp_bt_text">View on Facebook</p>
                    <button @click="handleClicks(3)" class="exp_bt" id="Facebook">
                        <img src="../../public/UiAssets/facebook_icon.svg" class="img_icon" id="Facebook">
                    </button>              
                </div>
                <div id="SnapContainner" style="display:none">
                    <p class="exp_bt_text">View on Snapchat</p>
                    <button @click="handleClicks(4)" class="exp_bt" id="SnapChat">
                        <img src="../../public/UiAssets/snap_icon.svg" class="img_icon" id="SnapChat" style="filter: invert(0%) sepia(0%) saturate(7493%) hue-rotate(300deg) brightness(1%) contrast(100%);">
                    </button>              
                </div>
                <div id="intaContainer">
                   <p class="exp_bt_text">View on Instagram</p>
                     <button @click="handleClicks(2)" class="exp_bt" id="Instagram">
                        <img src="../../public/UiAssets/instagram_icon.svg" class="img_icon" id="Instagram">
                    </button>                                    
                </div>
                <div id="webBtnContainer">
                    <p class="exp_bt_text">View on Browser</p>
                    <button @click="handleClicks(1)" class="exp_bt" id="Web">
                        <img src="../../public/UiAssets/web_icon.svg" class="img_icon" id="Web">
                    </button>                   
                </div>                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"ExperiencePage",
    props: [
        "links"
    ],
    
    data(){
        return {
           tshirt: null,
           collection: null     
        }
    },
    methods:{
        handleClicks(n){
            switch(n){
                case 1:
                    if(this.links.webar != ""){
                        window.open(this.links.webar, "_self")
                    }else{
                        this.$emit("fromExpPage",{type:"webar"})
                    }

                    this.$gtag.event('Web', {
                    'event_category':'collection ='+ this.collection+'|| '+'Tshirt ='+this.tshirt,
                    'event_label': 'Button_Click',
                    'value': 121
                     })
                    break;
                case 2:
                    window.open(this.links.insta, "_blank")                   
                    this.$gtag.event('Instagram', {
                    'event_category':'collection ='+ this.collection+'|| '+'Tshirt ='+this.tshirt,
                    'event_label': 'Button_Click',
                    'value': 122
                     })
                    break;
                case 3:
                    window.open(this.links.face, "_blank")
                    this.$gtag.event('Facebook', {
                    'event_category':'collection ='+ this.collection+'|| '+'Tshirt ='+this.tshirt,
                    'event_label': 'Button_Click',
                    'value': 123
                     })
                    break;
                case 4:
                    window.open(this.links.snap, "_blank")
                    break;
            }
        }
    },
    mounted(){
        // await userStore.getAll();
        //     console.log("hiramani"+this.links);
        //     if(this.links.insta == "onlyWebAR"){
        //         window.location.href = this.links.webar;
        //          }
    },
    created: function () { 
        if(this.$route.query.col == "nuvabipa"){
            const texts = document.getElementsByClassName("exp_bt");
            for (let item of texts) {
                item.style.display = "none";
             }
            window.location.href = "https://scan.awwgment.com/Awww/niva_bupa.html";
        } 
        this.tshirt = this.$route.query.con;
        this.collection = this.$route.query.col;
        //to turn off webAR button in selected collection 
            if(this.$route.query.con == "mug" && this.$route.query.col == "web3in"){    
              const WebBtnInterval =  setInterval(() => {
                    if(document.getElementById("webBtnContainer")){
                        document.getElementById("webBtnContainer").style.display = "none" 
                        clearInterval(WebBtnInterval);
                    }                   
                }, 100);
            } else if(this.$route.query.con == "magazine_ad"){
                const WebBtnInterval =  setInterval(() => {
                    if(document.getElementById("webBtnContainer")){
                        document.getElementById("FbContainner").style.display = "none";
                        document.getElementById("intaContainer").style.display = "none";
                        clearInterval(WebBtnInterval);
                    }                   
                }, 100);
            } else if(this.$route.query.con == "diary" && this.$route.query.col == "renewbuy"){
                const WebBtnInterval =  setInterval(() => {
                    if(document.getElementById("webBtnContainer")){
                        document.getElementById("FbContainner").style.display = "none";
                        document.getElementById("intaContainer").style.display = "none";
                        clearInterval(WebBtnInterval);
                    }                   
                }, 100);
            } else if(this.$route.query.con == "tshirt" && this.$route.query.col == "ntt"){
                const WebBtnInterval =  setInterval(() => {
                    if(document.getElementById("webBtnContainer")){
                        document.getElementById("FbContainner").style.display = "none";
                        document.getElementById("intaContainer").style.display = "none";
                        clearInterval(WebBtnInterval);
                    }                   
                }, 100);
            }  else if(this.$route.query.con == "tshirt" && this.$route.query.col == "coffeebeans"){
                const WebBtnInterval =  setInterval(() => {
                    if(document.getElementById("webBtnContainer")){
                        document.getElementById("FbContainner").style.display = "none";
                        document.getElementById("intaContainer").style.display = "none";
                        clearInterval(WebBtnInterval);
                    }                   
                }, 100);
            }  else if(this.$route.query.con == "mug" && this.$route.query.col == "coffeebeans"){
                const WebBtnInterval =  setInterval(() => {
                    if(document.getElementById("webBtnContainer")){
                        document.getElementById("FbContainner").style.display = "none";
                        document.getElementById("intaContainer").style.display = "none";
                        clearInterval(WebBtnInterval);
                    }                   
                }, 100);
            }  else if(this.$route.query.con == "stay" && this.$route.query.col == "summer21in"){
                const WebBtnInterval =  setInterval(() => {
                    if(document.getElementById("webBtnContainer")){
                        document.getElementById("webBtnContainer").style.display = "none" 
                        clearInterval(WebBtnInterval);
                    }                   
                }, 100);
            }  else if(this.$route.query.con == "invite" && this.$route.query.col == "NetworkScience"){
                const WebBtnInterval =  setInterval(() => {
                    if(document.getElementById("webBtnContainer")){
                        document.getElementById("FbContainner").style.display = "none";
                        document.getElementById("intaContainer").style.display = "none";
                        clearInterval(WebBtnInterval);
                    }                   
                }, 100);
            }  else if(this.$route.query.con == "Hoodie" && this.$route.query.col == "IIM"){
                const WebBtnInterval =  setInterval(() => {
                    if(document.getElementById("webBtnContainer")){
                        document.getElementById("FbContainner").style.display = "none";
                        document.getElementById("SnapContainner").style.display = "block";
                        clearInterval(WebBtnInterval);
                    }                   
                }, 100);
            }  else if(this.$route.query.col == "Hollister" && this.$route.query.con == "Bag"){
                const WebBtnInterval =  setInterval(() => {
                    if(document.getElementById("webBtnContainer")){
                        document.getElementById("FbContainner").style.display = "none";
                        document.getElementById("intaContainer").style.display = "none";
                        clearInterval(WebBtnInterval);
                    }                   
                }, 100);
            }                            
        },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
#exp_container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(rgb(255,42,178), rgb(8,0,191));
    z-index: 9999999;
    overflow: hidden;
}
#topborder{
    height: 80px;
}
#logo{
    position: absolute;
    top: 4px;
    left: 43vw;
    height: 55px;
}
#exp_flex{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
}
#bg_container{
    width: 75vw;
    height: 70vh;
    /* background-color: rgba(220,220,220, 0.5); */
    border-radius: 40px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.exp_bt{
    width:  22vw;
    height: auto;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin: 7px;
    background-color: #FCEE35 ;
}


.exp_bt_text{
    font-family: 'Roboto', sans-serif;
    color: white;
    font-size: 18px;
    margin-bottom: -2px;
}
.exp_bt:active{
    color:black
}
.img_icon{
    vertical-align: middle;
}
#notify{
    font-family: 'Roboto', sans-serif;
    color: white;
    font-weight: bold;
    padding: 5px;
    font-size: 6vw;
}
@media (max-height: 734px) {
  #notify{
    font-size: 4.7vw;
  }
}


</style>
