<template>
    <button @click="muteMedia" class="outer-circle" ref="soundContainer">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250.19 249.8" v-if="!muted" id="media-on" > 
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path
                        d="M166.64,125.11v68.7q0,23.49,0,47c0,5.78-3.8,9.31-9.56,9a8.6,8.6,0,0,1-4.28-1.69Q121.09,227,89.42,205.79,73.28,195,57.13,184.25a4.88,4.88,0,0,0-2.92-.63c-14.82,0-29.65,0-44.48,0-4.57,0-7.95-2.07-9.39-6.29A7.18,7.18,0,0,1,0,174.85Q0,125,0,75.13A8.42,8.42,0,0,1,8.5,66.59q22.52,0,45,0a7,7,0,0,0,4-1.19q23.68-15.84,47.39-31.67Q127,19,149.06,4.35c2.18-1.45,4.19-3.22,6.74-4a8.45,8.45,0,0,1,10.83,8.16q0,28.44,0,56.86Q166.66,95.25,166.64,125.11Z" />
                    <path
                        d="M232.55,124.21a73.09,73.09,0,0,0-.57-12.94,134.16,134.16,0,0,0-4.86-23.93,130.22,130.22,0,0,0-9.78-23.53,115.81,115.81,0,0,0-16.24-23c-1.77-2-3.47-3.93-3.47-6.84,0-3.6,1.89-6.9,4.8-8.15,3.61-1.56,7.19-1,9.88,1.78a123.16,123.16,0,0,1,12.12,15,139.37,139.37,0,0,1,21.94,50.82,129.26,129.26,0,0,1,3.2,27.29c0,2,.81,3.89.57,5.87-.66,5.6-.66,11.25-1.54,16.85-.52,3.35-1,6.72-1.59,10.06a131.46,131.46,0,0,1-5.58,20.65,139.89,139.89,0,0,1-12.4,26.1,135.27,135.27,0,0,1-16.33,21.68c-3.5,3.77-8.65,4.09-12.3.76a8.59,8.59,0,0,1-.27-12.48A120.44,120.44,0,0,0,222.27,176,122.86,122.86,0,0,0,229.38,154,127.79,127.79,0,0,0,232.55,124.21Z" />
                    <path
                        d="M217.16,122.55a117.27,117.27,0,0,1-2.62,26.31A99,99,0,0,1,209,166.55a101.82,101.82,0,0,1-19.69,30.76c-5,5.29-13,2.15-14.28-3.39-.71-3.21-.35-5.93,1.85-8.37A86.48,86.48,0,0,0,196,151.63a110.4,110.4,0,0,0,3.28-15.71,87.64,87.64,0,0,0,.37-18.94A86.51,86.51,0,0,0,195,95a89,89,0,0,0-10.23-20.55,86.15,86.15,0,0,0-7.72-9.84c-2.13-2.32-3.22-5-2.33-8.18A8.57,8.57,0,0,1,182,49.93a7.91,7.91,0,0,1,7.27,2.66,100.15,100.15,0,0,1,16,22.76,107.2,107.2,0,0,1,8.51,22.49A109.94,109.94,0,0,1,217.16,122.55Z" />
                </g>
            </g>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250.2 249.8" v-if="muted" id="media-on"> 
            <g>
                <path d="M166.6,125.1v68.7c0,15.7,0,31.3,0,47c0,5.8-3.8,9.3-9.6,9c-1.6-0.2-3-0.7-4.3-1.7c-21.1-14.1-42.3-28.2-63.4-42.3
                    c-10.8-7.2-21.5-14.4-32.3-21.5c-0.9-0.5-1.9-0.7-2.9-0.6c-14.8,0-29.6,0-44.5,0c-4.6,0-7.9-2.1-9.4-6.3c-0.3-0.8-0.4-1.6-0.3-2.5
                    c0-33.2,0-66.5,0-99.7c-0.1-4.6,3.6-8.5,8.3-8.5c0.1,0,0.1,0,0.2,0c15,0,30,0,45,0c1.4,0,2.8-0.4,4-1.2
                    c15.8-10.6,31.6-21.1,47.4-31.7c14.7-9.8,29.5-19.6,44.2-29.4c2.2-1.5,4.2-3.2,6.7-4c4.5-1.3,9.2,1.2,10.5,5.7
                    c0.2,0.8,0.3,1.6,0.3,2.4c0,19,0,37.9,0,56.9C166.7,85.3,166.7,105.2,166.6,125.1z"/>
                <line class="st0" x1="175.4" y1="22.3" x2="240.9" y2="231.4"/>
                <line class="st0" x1="240.9" y1="22.3" x2="175.4" y2="231.4"/>
            </g>
        </svg>
    </button>
</template>
<script>
export default {
    name: "SoundControl",
    props:[
        "audioEl",
        "recordingAudioEl",
        "videoEl"
    ],
    data(){
        return {
            ui_path: "./UiAssets/",
            muted: false,
            lastAudioVol: null,
        }
    },
    methods:{
        muteMedia(){
            this.muted = !this.muted;
            // console.log(this.muted)
            if(this.muted){
                if(this.audioEl != null){
                    // this.lastAudioVol = this.audioEl.volume;
                    // this.audioEl.volume = 0;
                    this.audioEl.muted = true;
                    this.recordingAudioEl.muted = true;
                    // console.dir(this.audioEl)
                }

                if(this.videoEl != null){
                    // this.lastVideoVol = this.videoEl.volume;
                    // this.videoEl.volume = 0;
                    this.videoEl.muted = true;
                }
            }else{
                if(this.audioEl != null){
                    // this.audioEl.volume = this.lastAudioVol;
                    this.audioEl.muted = false;
                    this.recordingAudioEl.muted = false;
                }

                if(this.videoEl != null){
                    // this.videoEl.volume = this.lastVideoVol;
                    this.videoEl.muted = false;
                }
            }
        },
        // volumeInput(e){
        //     let vol = e.target.value/100;
            
        //     if(this.audioEl != null){
        //         this.audioEl.volume = vol;
        //     }

        //     if(vol == 0){
        //         this.muted = true;
        //     }else{
        //         this.muted = false;
        //     } 
        // },
        playAudio(){
            if(this.audioEl != null){
                this.audioEl.play();
                // this.recordingAudioEl.play();
                // this.audioEl.muted = false;
            }  
        },
        pauseAudio(){
            if(this.audioEl != null){
                this.audioEl.pause();
                // this.recordingAudioEl.pause();
                // this.audioEl.muted = true;
            }         
        }
    },
}
</script>
<style scoped>
.outer-circle{
    cursor: pointer;
    /* top: 85vh; */
    top: calc(var(--vh, 1vh) * 83.5);
    left: 76vw;
    position: absolute;
    width: 55px;
    height: 55px;
    background-color: transparent;
    background-size: contain;
    background-position: center center;
    background-origin: border-box;
    z-index: 10000;
    outline: none;
    border: none;
    fill: white;
    /* outline: none; */
}
.st0{
    fill:none;
    stroke:#fff;
    stroke-width:14;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-miterlimit:10;
}
</style>