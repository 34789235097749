<template>
  <div id="app">
    <!-- SHOW APP PERFORMANCE -->
    <!-- <div id="stats" class="ui stats">
      <div id="stats1" class="stats-item">
          <p class="stats-item-title">
              Main
          </p>
      </div>
      <div id="stats2" class="stats-item">
          <p class="stats-item-title">
              Worker
          </p>
      </div>
    </div> -->
    <!-- <ArComponent :contentArr="queries" :loadType="loadType" v-if="loadType == 'qr'" :statsMain="statsMain" :statsWorker="statsWorker"></ArComponent> -->
    <div v-if="!selectExperience">
      <ExperiencePage v-on:fromExpPage="selectedExperience" :links="expLinks"></ExperiencePage>
    </div>
    <div v-else> 
      <ArComponent :contentArr="queries" :loadType="loadType" v-if="loadType == 'qr'" :selectExperience="selectExperience"></ArComponent>
      <Orb :contentArr="queries" :loadType="loadType" v-else-if="loadType == 'col'" :selectExperience="selectExperience"></Orb>
    </div>
 
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ArComponent from '../components/ArComponent';
import Orb from '../components/Orb';
import ExperiencePage from '../components/ExperiencePage';
// import Stats from 'stats.js'
// import * as THREE from 'three';

export default {
  name: 'App',
  components: {
    ArComponent,
    Orb,
    ExperiencePage
  },
  data(){
    return {
      version: null,
      queries: [],
      loadedFile: null,
      loadType: null,
      selectExperience: false,
      expLinks: null
    }
  },
  methods:{
    async loadCollections(URL){
      let _this = this;
      await this.makeRequest('GET', URL)
      .then(function (data) {
        _this.loadedFile = JSON.parse(data);
        if(_this.$route.query.con != null){
          for(let i = 0; i < _this.loadedFile.length; i++){
            if(_this.loadedFile[i].name == _this.$route.query.con){
              _this.queries = [_this.parseTimeQuerie(_this.loadedFile[i])]
              
              _this.expLinks = {
                webar: _this.queries[0].webarLink != null ? _this.queries[0].webarLink : "",
                insta: _this.queries[0].instagramLink != null ? _this.queries[0].instagramLink : "",
                face: _this.queries[0].facebookLink != null ? _this.queries[0].facebookLink : "",
                snap: _this.queries[0].snapchatLink != null ? _this.queries[0].snapchatLink : ""
              }
              
              break;
            }
          }
        }else{
          _this.queries = _this.loadedFile;
        }
      })
      .catch(function (err) {
        console.error('Augh, there was an error!', err);
      });

      if(_this.$route.query.con != null ){
        _this.loadType = "qr";
        console.log("Load from QR code");
      }else if(_this.$route.query.spe == "t"){
        _this.loadType = "qr";
        console.log("Load from Special");
      }else{
        _this.loadType = "col";
        console.log("Load from Collections");
      }
    },
    makeRequest (method, url) {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open(method, url);
        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            resolve(xhr.response);
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          }
        };
        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        };
        xhr.send();
      });
    },
    selectedExperience(){
      this.selectExperience = true;
    },
    resizeVh(){
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    parseTimeQuerie(data){
      let d = new Date();
      //let timezone = d.getTimezoneOffset();

      if(data.alternative_models != null ){
        for(let i = 0; i < data.alternative_models.length; i++){
          let alt = data.alternative_models[i];
         // let timezone_str = alt.timezone_utc.split(".");
          //let timezone_input_hr = parseInt(timezone_str[0])*60;
          //let timezone_input_min = 0;

         /* if(timezone_str.length > 1){
            timezone_input_min = timezone_input_hr > 0 ? (parseInt(timezone_str[1])/10)*60 : ((parseInt(timezone_str[1])/10)*60)*-1
          }*/
          
          //let timeDiff = (timezone_input_hr + timezone_input_min) - (timezone * -1);
          let currentTime = ((d.getHours() * 60) + d.getMinutes());
          console.log("hello world"+currentTime);
          let timeRange = this.parseTimeRange(alt)
          console.log("start time"+timeRange.start+"end time"+timeRange.end)
          if(currentTime > timeRange.start && currentTime < timeRange.end){
            let returnObj = alt;

            returnObj.name = data.name;
            returnObj.markerUrl = data.markerUrl;
            returnObj.orbUrl = data.orbUrl;
            returnObj.srcImage = data.srcImage;
            returnObj.dpi = data.dpi;
            returnObj.src_width = data.src_width;
            returnObj.src_height = data.src_height;

            if(alt.instagramLink == null && data.instagramLink != null){
              alt.instagramLink = data.instagramLink; 
            }

            if(alt.facebookLink == null && data.facebookLink != null){
              alt.facebookLink = data.facebookLink; 
            }

            if(alt.snapchatLink == null && data.snapchatLink != null){
              alt.snapchatLink = data.snapchatLink; 
            }

            if(alt.webarLink == null && data.webarLink != null){
              alt.webarLink = data.webarLink; 
            }

            console.log("====== SHOWING MODEL FROM " + alt.time_start + " - " + alt.time_end + " ======");
            return returnObj;
          }
        }
      }
     
      console.log("No alternative model set");
      // this.getTimeOffset()
      return data;
    },
    parseTimeRange(alt){
      let start = alt.time_start.toString();
      let end = alt.time_end.toString();

      let returnObj = {
        start: 0,
        end: 0
      }
      if(start.length > 1){
        if(start.length<4){
          start= "0"+start;
        }
        returnObj.start = (parseInt(start[0]+start[1]) * 60) + parseInt(start[2]+start[3]);
      }

      if(end.length > 1){
        if(end.length<4){
          end= "0"+end;
        }
        returnObj.end = (parseInt(end[0]+end[1]) * 60) + parseInt(end[2]+end[3]);
      }

      return returnObj;
    }
  },
  mounted(){
    // this.statsMain = new Stats();
    // this.statsMain.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    // document.getElementById("stats1").appendChild(this.statsMain.dom);

    // this.statsWorker = new Stats();
    // this.statsWorker.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    // document.getElementById("stats2").appendChild(this.statsWorker.dom);
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener("resize", this.resizeVh)

    this.version = process.env.VUE_APP_VERSION;
    console.log("BUILD NUMBER: ", this.version)

    if(location.href != location.origin + location.pathname + location.hash + location.search){
      window.location.href = location.origin + location.pathname + location.hash + location.search;
    }

    if(Object.entries(this.$route.query).length != 0){
      this.loadCollections("./collections/" + this.$route.query.col + ".json");
    }

    this.$root.$on("returnToExp", ()=>{
      this.selectExperience = false;
    })
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.resizeVh)
  }
}
</script>

<style>
#version{
  position: absolute;
  top: 0;
  left: 0;
  font-size: 15px;
  z-index: 1000000;
}
.stats {
  top: 0;
  left: 0;
  z-index: 200;
  margin: 0.5rem;
  padding: 0.5rem 0.5rem 0;
}

.stats-item {
  margin: 0 0 0.5rem;

}

.stats-item-title {
  margin: 0 0 0.25rem;

  font-size: 0.75rem;
}

#stats div {
  position: relative !important;
}

</style>
