<template>
    <div> 
        <div id="popupbox">
            <div>
            <p>Please allow us to open the link in new tab</p>
            <button @click="open3dBtnLink()" id="redirectbtn">Allow</button>
            <button @click="closePopup()" id="popupclosebtn">Decline</button>
            </div>
        </div>      
        <div id="ar_container">
            <!-- <div id="topbar">
                <img id="topborder" src="../../public/UiAssets/topbar.svg">
                <img id="logo" src="../../public/UiAssets/logo.svg">
            </div> -->
            <img src="UiAssets/back.svg" @click="close" id="close_bt" v-show="enableRun && !capturedContent && selectExperience">
            <LoadingIcon class="loadingIcon" v-show="searchingBool"></LoadingIcon> 
            <SearchingIcon class="searchingIcon" v-show="enableRun && !capturedContent && selectExperience" :icon="searchingIcon"></SearchingIcon> 
            <ImageCapture class="imageCapture"  v-show="enableRun && !capturedContent && selectExperience"></ImageCapture>
            <VideoCapture class="videoCapture"  v-show="enableRun && !capturedContent && selectExperience"></VideoCapture>
            <CaptureModal :content="tempContent" v-if="capturedContent"></CaptureModal>
            <SoundControl :audioEl="soundEl" :recordingAudioEl="recordingSoundEl" :videoEl="mainVideoEl" ref="SoundControl" v-show="enableRun && !capturedContent && selectExperience"></SoundControl>
            <PopupModal :link="linkUrl" v-show="showLinkModal" class="link_popup"></PopupModal>
            <div v-if="loadType == 'qr'">
                <video
                    loop
                    autoplay
                    muted
                    playsinline
                    id="video">
                </video>
                <video
                    loop
                    autoplay
                    muted
                    playsinline
                    id="recordVideo">
                </video>
                <canvas class="canvas" id="canvas"></canvas>
            </div>
        </div>
    </div>
</template>

<script>

import PopupModal from './special/PopUpModal.vue';
import LoadingIcon from './LoadingIcon.vue';
import SearchingIcon from './SearchingIcon.vue';
import ImageCapture from './ImageCapture.vue';
import VideoCapture from './VideoCapture.vue';
import CaptureModal from './CaptureModal.vue';
import SoundControl from './SoundControl.vue';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import * as Filter from '../assets/oneEuroFilter.js';

// import enableInlineVideo from 'iphone-inline-video';
// import * as VSM from 'video-stream-merger';

export default {
    name: "ArComponent",
    props:[
        "contentArr",
        "loadType",
        "canvasProp",
        "videoProp",
        'mediaStreamProp',
        'selectExperience',
        'statsMain',
        'statsWorker'
    ],
    components:{
        LoadingIcon,
        SearchingIcon,
        ImageCapture,
        VideoCapture,
        CaptureModal,
        SoundControl,
        PopupModal,
    },
    data(){
        return {
            enableRun: false,
            canvas: null,
            video: null,
            videoStream: null,
            mediaStream: null,
            canvas_process: null,
            context_process: null,

            interpolationFactor: 24,
            trackedMatrix: {
                // for interpolation
                delta: [
                    0,0,0,0,
                    0,0,0,0,
                    0,0,0,0,
                    0,0,0,0
                ],
                interpolated: [
                    0,0,0,0,
                    0,0,0,0,
                    0,0,0,0,
                    0,0,0,0
                ]
            },
            
            root: new THREE.Object3D(),
            scene: null,
            camera: null,
            renderer: null,

            environmentMaps: null,

            ambientLight: null,
            spotLightTop: null,
            spotLightLeft: null,
            spotLightRightAngled: null,

            input_width: null, 
            input_height: null,
            vw: null,
            vh: null,
            sw: null,
            sh: null,
            pscale: null, 
            sscale: null,
            w: null, 
            h: null,
            pw: null, 
            ph: null,
            ox: null, 
            oy: null,
            worker: null,
            modelbtnlink:null,

            world: null,
            index: null,

            lasttime: null,
            time: 0,
            clock: new THREE.Clock(),
            
            camera_para: '../camera_para.dat',
            worker_path: './js/artoolkit.multi_worker.js',

            kalman: null,     

            positionFilter: null,
            positionXFilter: null,
            positionYFilter: null,
            positionZFilter: null,
            rotationFilter: null,

            filterOpts:{
                freq: 10,
                mincut: 0.5,  //Decreasing the minimum cutoff frequency decreases slow speed jitter 
                beta: 0.9,    //Increasing the speed coefficient decreases speed lag.
                dcut: 1
            },
            filterXOpts:{
                freq: 20,
                mincut: 1,
                beta: 0.9,
                dcut: 1
            },
            filterYOpts:{
                freq: 20,
                mincut: 0.6,
                beta: 0.8,
                dcut: 1
            },
            filterZOpts:{
                freq: 60,
                mincut: 0.6,
                beta: 0.7,
                dcut: 1
            },
            filter_rot_opts:{
                freq: 50,
                mincut: 0.7,
                beta: 1,
                dcut: 1
            },

            last_transform_pos:  null,
            user_moving: false,
            
            loadedAR: false,
            searchingBool: false,
            searchingIcon: null,
            
            // expLinks: null,

            recordVideo: true,
            recorder: null,
            recordingTimeMS: 1000,
            recordingData: [],
            recordCanvas: null,
            recordContext: null,
            tempAudioSource: null,
            tempDest: null,
            audioCtx: null,
            mediaAudioSource: null,
            audioPlayRecord: null,
            scaleToVideo: null,
            capturedContent: false,
            tempContent: {
                url: null,
                time: null,
                type: null,
                ios: null
            },

            soundEl: null,
            recordingSoundEl: null,
            mainVideoEl: null,
            linkUrl: "",
            showLinkModal: false,
            tempCtx: null,
            
            mouse: new THREE.Vector2(),
            raycaster: new THREE.Raycaster(),
            buttonObjects: []
        };
    },
    methods:{
        // ----------- INITIATION AND SETUP FUNCTIONS ----------- 
        // Test code to detect back camera / not implemented in production
        async checkCameras(){
            let constraints = navigator.mediaDevices.getSupportedConstraints();
            if(!constraints.deviceId){
                console.error("device id not supported")
            }

            let cameras = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            }).then(() => {    
                return navigator.mediaDevices.enumerateDevices()
                .then ( function (devices) {
                    return devices.filter(device => device.kind === 'videoinput')
                })
            })
            
            let rear;
            for(let i = 0; i < cameras.length; i++){
                let cam = cameras[i];
                if(cam.label.includes("back")){
                    rear = cam.deviceId;
                    break;
                }
            }
            if(rear == null){
                console.log("null back")
                rear = cameras[0].deviceId;
            }
            console.log(rear, cameras);
            return rear;
        },
        // Initiate device camera and filters, calls Three.js setup
        async initAR(){
            console.log(this.contentArr)
            this.positionFilter = new Filter.OneEuroFilterVector3(this.filterOpts.freq);
            this.positionXFilter = new Filter.OneEuroFilterVector3(this.filterXOpts.freq);
            this.positionYFilter = new Filter.OneEuroFilterVector3(this.filterYOpts.freq);
            this.positionZFilter = new Filter.OneEuroFilterVector3(this.filterZOpts.freq);
            this.rotationFilter = new Filter.OneEuroFilterVector3(this.filter_rot_opts.freq);

            this.root.matrixAutoUpdate = false;

            let displayVideo = document.querySelector("#video");
            
            if(this.loadType == "col"){
                this.start();
            }else if(this.loadType == "qr"){
                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                    let hint = {
                        audio: false,
                        video: {
                            // width: { ideal: 1280},
                            // height: { ideal: 720 },
                            width: { min: 1024 }, 
                            height: { min: 540 },
                            // width: { ideal: 1024 }, 
                            // height: { ideal: 540 },
                            
                            facingMode: {ideal: "environment"}
                        },
                    };
                    
                    let _this = this;

                    this.mediaStream = navigator.mediaDevices.getUserMedia(hint).then( stream => {
                        _this.videoStream = stream;
                        displayVideo.srcObject = stream;
                        _this.video.srcObject = stream;
                        _this.video.captureStream = _this.video.captureStream || _this.video.mozCaptureStream;
                        _this.video.addEventListener("loadedmetadata", function() {
                            _this.video.play();
                            console.log(_this.video.videoWidth, _this.video.videoHeight)
                            _this.input_width = _this.video.videoWidth;
                            _this.input_height = _this.video.videoHeight;
                        });

                        _this.video.addEventListener("playing", function(){
                            _this.start();
                        });
                    })
                }
            }
        },
        // Setup Three.js, calls JsArtoolkit5 initiation and Dynamic Content loading
        async start() {
            this.scene = new THREE.Scene();

            this.camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 0.1, 5000 );
            this.camera.matrixAutoUpdate = false;

            this.renderer = new THREE.WebGLRenderer({canvas: this.canvas, alpha: true, antialias: true, preserveDrawingBuffer: true});
            this.renderer.setSize( window.innerWidth, window.innerHeight );
            this.renderer.precision = "mediump";
            this.renderer.shadowMap.enabled = true;
            this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            this.renderer.outputEncoding = THREE.sRGBEncoding;
            this.renderer.physicallyCorrectLights = true;
            this.renderer.setPixelRatio(window.devicePixelRatio);

            // this.pmremGenerator = new THREE.PMREMGenerator( this.renderer );
            // this.pmremGenerator.compileEquirectangularShader();

            this.ambientLight = new THREE.AmbientLight( 0x404040, 5); // soft white light
            this.scene.add( this.ambientLight );

            this.envlight = new THREE.HemisphereLight( 0xffffbb, 0x080820, 1.2);
            this.scene.add( this.envlight );
           
            // this.environmentMaps = new THREE.CubeTextureLoader() .setPath( '../../public/envassets/' ) .load( [ 'posx.jpg', 'negx.jpg', 'posy.jpg', 'negy.jpg', 'posz.jpg', 'negz.jpg' ] );
            // this.environmentMaps.encoding = THREE.sRGBEncoding;

            // this.scene.background = this.environmentMaps;

            this.scene.add(this.camera);
            this.scene.add(this.root);

            this.setupLights();
            this.load();
        },
        // Window scaling and setup worker messages
        load(){
            this.vw = this.input_width;
            this.vh = this.input_height;

            this.pscale = 320 / Math.max(this.vw, this.vh / 3 * 4);
            this.sscale = this.isMobile() ? window.outerWidth / this.input_width : 1;

            this.sw = this.vw * this.sscale;
            this.sh = this.vh * this.sscale;

            this.w = this.vw * this.pscale;
            this.h = this.vh * this.pscale;
            this.pw = Math.max(this.w, this.h / 3 * 4);
            this.ph = Math.max(this.h, this.w / 4 * 3);
            this.ox = (this.pw - this.w) / 2;
            this.oy = (this.ph - this.h) / 2;
            this.canvas_process.width = this.pw;
            this.canvas_process.height = this.ph;
            this.recordCanvas.width = this.input_width;
            this.recordCanvas.height = this.input_height;

            this.renderer.setSize(this.sw, this.sh);
            // Initiate JsArtoolkit5
            this.worker.postMessage({ type: "load", pw: this.pw, ph: this.ph, camera_para: this.camera_para});
            
            let _this = this;
            this.worker.onmessage = function(ev) {
                var msg = ev.data;
                switch (msg.type) {
                    case "loaded": {
                        var proj = JSON.parse(msg.proj);
                        var ratioW = _this.pw / _this.w;
                        var ratioH = _this.ph / _this.h;
                        proj[0] *= ratioW;
                        proj[4] *= ratioW;
                        proj[8] *= ratioW;
                        proj[12] *= ratioW;
                        proj[1] *= ratioH;
                        proj[5] *= ratioH;
                        proj[9] *= ratioH;
                        proj[13] *= ratioH;
                        
                        if(_this.loadType == "qr"){
                            
                            _this.dynamicContentLoading();
                        }
                        // _this.camera.projectionMatrix = _this.setMatrix(_this.camera.projectionMatrix, proj);
                        break;
                    }
                    case "endLoading": {
                        if (msg.end == true) {         
                            document.querySelector(".loadingIcon").style.display = "none"; 
                            _this.onWindowResize();
                            _this.enableRun = true;
                            _this.tick();
                        }
                        break;
                    }
                    case "found": {
                        if(_this.enableRun){
                            document.querySelector(".searchingIcon").style.display = "none"; 
                            _this.found(msg);      
                        }
                        break;
                    }
                    case "not found": {
                        if(_this.enableRun){
                            document.querySelector(".searchingIcon").style.display = "block";      
                            _this.found(null);
                        }
                        break;
                    }
                }
                if(_this.enableRun){
                    _this.process();
                    // Debug app fps
                    // _this.statsWorker.update();
                }
                
            };
        },
        setupLights(){
            // console.log("Setting up Lights")

            this.spotLightTop = new THREE.PointLight( 0xffffff, 1, 0 );
            this.spotLightTop.castShadow = true;
            this.spotLightTop.position.set(200,500,100);
            this.root.add(this.spotLightTop);
            
            this.spotLightLeft = new THREE.SpotLight(0xffffff, 0.1, 0);
            this.spotLightLeft.castShadow = true;
            this.spotLightLeft.position.set(-100,200,200);
            this.root.add(this.spotLightLeft);
         
            this.spotLightRightAngled = new THREE.SpotLight(0xffffff, 5, 0);
            this.spotLightRightAngled.castShadow = true;
            this.spotLightRightAngled.position.set(300, 400, 350);
            this.root.add(this.spotLightRightAngled);

            let _this = this;
            new RGBELoader()
            .setDataType( THREE.UnsignedByteType )
            .setPath( './envassets/' )
            .load( 'christmas_photo_studio_04_1k.hdr', function ( texture ) {

                let pmremGenerator = new THREE.PMREMGenerator( _this.renderer );
                _this.envMap = pmremGenerator.fromEquirectangular( texture ).texture;

                // _this.scene.background = envMap;
                _this.scene.environment = _this.envMap;

                texture.dispose();
                pmremGenerator.dispose();
            })
            this.pmremGenerator = new THREE.PMREMGenerator( this.renderer );
            this.pmremGenerator.compileEquirectangularShader();

        },
        calibrateModels(){ 
            console.log("Calibrating Models");
            for(let i = 0; i < this.contentArr.length; i++){
                if(this.contentArr[i].modelUrl != null || this.contentArr[i].videoUrl != null || this.contentArr[i].imageUrl != null){
                    let width = this.contentArr[i].src_width;
                    let height = this.contentArr[i].src_height;
                    let dpi = this.contentArr[i].dpi;
                    // console.log(width, height, dpi)
                    // CONVERT PIXEL TO MM
                    let w = width / dpi * 2.54 * 10;
                    let h = height / dpi * 2.54 * 10;
                    
                    // SCALE OBJECT
                    var box = new THREE.Box3().setFromObject( this.contentArr[i].content );
                    var size = new THREE.Vector3();
                    box.getSize( size );
                    var scaleVecY = (h/size.y);
                    var scaleVecX = (w/size.x);

                    if(this.contentArr[i].full_marker_media){
                        this.contentArr[i].content.scale.setY(scaleVecY + (scaleVecY * 0.5));
                        this.contentArr[i].content.scale.setX(scaleVecX + (scaleVecX * 0.5));
                    }else{
                        this.contentArr[i].content.scale.setScalar(scaleVecX + (scaleVecX * 0.5));
                    }

                    // SET SCALE
                    if(this.contentArr[i].scale != null){
                        this.contentArr[i].content.scale.x += (this.contentArr[i].content.scale.x * parseFloat(this.contentArr[i].scale[0]));
                        this.contentArr[i].content.scale.y += (this.contentArr[i].content.scale.y * parseFloat(this.contentArr[i].scale[1]));
                        this.contentArr[i].content.scale.z += (this.contentArr[i].content.scale.z * parseFloat(this.contentArr[i].scale[2]));
                    }

                    // SET MODEL ORIGIN AS BOX CENTER
                    var box2 = new THREE.Box3().setFromObject( this.contentArr[i].content );
                    var boxCenter = new THREE.Vector3();
                    box2.getCenter(boxCenter);

                    var dist = new THREE.Vector3((w/2) - boxCenter.x, (h/2) - boxCenter.y);

                    this.contentArr[i].content.position.x = dist.x;
                    this.contentArr[i].content.position.y = dist.y;
                    this.contentArr[i].content.position.z = 30;

                    if(this.contentArr[i].videoEffects != null){
                        for(let j = 0; j < this.contentArr[i].videoEffects.length; j++){
                            
                            if(this.contentArr[i].videoEffects[j].addToModel == null){         
                                var boxEffect = new THREE.Box3().setFromObject(this.contentArr[i].videoEffects[j].effect);
                                var sizeEffect = new THREE.Vector3();
                                boxEffect.getSize( sizeEffect );
                                var scaleVecYEffect = (h/sizeEffect.y);
                                var scaleVecXEffect = (w/sizeEffect.x);

                                this.contentArr[i].videoEffects[j].effect.scale.setScalar(scaleVecXEffect + (scaleVecXEffect * 0.5));
                                
                                if(this.contentArr[i].videoEffects[j].scale != null){
                                    this.contentArr[i].videoEffects[j].effect.scale.x += (this.contentArr[i].videoEffects[j].effect.scale.x * parseFloat(this.contentArr[i].videoEffects[j].scale[0]));
                                    this.contentArr[i].videoEffects[j].effect.scale.y += (this.contentArr[i].videoEffects[j].effect.scale.y * parseFloat(this.contentArr[i].videoEffects[j].scale[1]));
                                    this.contentArr[i].videoEffects[j].effect.scale.z += (this.contentArr[i].videoEffects[j].effect.scale.z * parseFloat(this.contentArr[i].videoEffects[j].scale[2]));
                                }else {
                                    this.contentArr[i].videoEffects[j].effect.scale.setX(scaleVecXEffect + (scaleVecXEffect * 0.5));
                                    this.contentArr[i].videoEffects[j].effect.scale.setY(scaleVecYEffect + (scaleVecYEffect * 0.5));
                                }

                                let globalPos = new THREE.Vector3();
                                // this.contentArr[i].videoEffects[j].effect.position
                                if(this.contentArr[i].videoEffects[j].position != null){
                                    console.log("user pos: ",this.contentArr[i].videoEffects[j].position)
                                    globalPos.x = dist.x + this.contentArr[i].videoEffects[j].position[0];
                                    globalPos.y = dist.y + this.contentArr[i].videoEffects[j].position[1];
                                    globalPos.z = this.contentArr[i].videoEffects[j].position[2];
                                }else{
                                    globalPos.x = dist.x;
                                    globalPos.y = dist.y;
                                    globalPos.z = 0;
                                }

                                // if(this.contentArr[i].videoEffects[j].addToModel != null && this.contentArr[i].videoEffects[j].addToModel){
                                //     console.log("global: ", globalPos)
                                //     let localPos = new THREE.Vector3().copy(this.contentArr[i].content.worldToLocal(globalPos))
                                //     console.log("local: ", localPos)
                                //     this.contentArr[i].videoEffects[j].effect.position.copy(localPos);
                                // }else{
                                this.contentArr[i].videoEffects[j].effect.position.copy(globalPos);
                                // }
                            
                                // localPos.multiplyScalar(2.0);

                                // console.log(this.contentArr[i].content.worldToLocal(globalPos))

                                // if(this.contentArr[i].videoEffects[j].addToModel != null && this.contentArr[i].videoEffects[j].addToModel){
                                //     this.configureModelChildren(this.contentArr[i].content, this.contentArr[i].videoEffects[j].effect)
                                // }
                            }
                        }
                    }

                    // SET POSITION
                    if(this.contentArr[i].position != null){
                        this.contentArr[i].content.position.x += this.contentArr[i].position[0];
                        this.contentArr[i].content.position.y += this.contentArr[i].position[1];
                        this.contentArr[i].content.position.z += this.contentArr[i].position[2];
                    }

                   
                }
            }
        },
        preProcessContent(){
            console.log("Preprocessing")
             for(let i = 0; i < this.contentArr.length; i++){
                if(this.contentArr[i].modelUrl != null){
                    this.contentArr[i].animations = null;
                    this.contentArr[i].content = null;
                    this.contentArr[i].loaded = null;
                    this.contentArr[i].mixer = null;
                }else if(this.contentArr[i].videoUrl != null){
                    let appEl = document.getElementById("app");
                    this.contentArr[i].videoEl = document.createElement('video');
                    // this.contentArr[i].videoEl.src = this.contentArr[i].videoUrl;
                    this.contentArr[i].videoEl.style.display = "none";
                    this.contentArr[i].videoEl.WebKitPlaysInline = true;
                    this.contentArr[i].videoEl.crossOrigin = 'anonymous';
                    this.contentArr[i].videoEl.loop = true;
                    this.contentArr[i].videoEl.setAttribute("playsinline", null)
                    if(this.isIOS()){
                        this.contentArr[i].videoEl.muted = true;
                    }

                    let source = document.createElement('source');
                    source.src = this.contentArr[i].videoUrl;
                    this.contentArr[i].videoEl.appendChild(source);

                    appEl.appendChild(this.contentArr[i].videoEl);

                    var texture = new THREE.VideoTexture( this.contentArr[i].videoEl );
                    var mat = new THREE.MeshLambertMaterial({color: 0xbbbbff, map: texture});
                    var planeGeom = new THREE.PlaneGeometry(1,1,1,1);
                    var plane = new THREE.Mesh(planeGeom, mat);
                    plane.name = this.contentArr[i].name;

                    this.contentArr[i].content = plane;
                    this.root.add(plane);
                }else if(this.contentArr[i].imageUrl != null){
                    var textureImage = new THREE.TextureLoader().load( this.contentArr[i].imageUrl );

                    var matImage = new THREE.MeshLambertMaterial({color: 0xbbbbff, map: textureImage});
                    var planeImageGeom = new THREE.PlaneGeometry(1,1,1,1);
                    var planeImage = new THREE.Mesh(planeImageGeom, matImage);
                    planeImage.name = this.contentArr[i].name;

                    this.contentArr[i].content = planeImage;
                    this.root.add(planeImage);
                } 

                if(this.contentArr[i].soundUrl != null){
                    this.contentArr[i].soundEl = document.createElement('audio');
                    this.contentArr[i].soundEl.src = this.contentArr[i].soundUrl;
                    this.contentArr[i].soundEl.type = "audio/mpeg";
                    this.contentArr[i].soundEl.volume = 0.3;

                    if(this.isIOS()){
                        this.contentArr[i].soundEl.muted = true;
                    }
                    this.contentArr[i].soundEl.pause();

                    this.contentArr[i].recordingAudio = this.contentArr[i].soundEl.cloneNode();
                }

                if(this.contentArr[i].videoEffects != null){
                    let appEl = document.getElementById("app");

                    for(let j = 0; j < this.contentArr[i].videoEffects.length; j++){
                        this.contentArr[i].videoEffects[j].videoEl = document.createElement('video');

                        this.contentArr[i].videoEffects[j].videoEl.WebKitPlaysInline = true;
                        this.contentArr[i].videoEffects[j].videoEl.style.display = "none";
                        this.contentArr[i].videoEffects[j].videoEl.crossOrigin = 'anonymous';
                        this.contentArr[i].videoEffects[j].videoEl.loop = true;
                        this.contentArr[i].videoEffects[j].videoEl.setAttribute("playsinline", null)
                        if(this.isIOS()){
                            this.contentArr[i].videoEffects[j].videoEl.muted = true;
                        }
                        let sourceAndroid = document.createElement('source');
                        let sourceIos = document.createElement('source');

                        sourceIos.src = this.contentArr[i].videoEffects[j].safariUrl;
                        sourceAndroid.src = this.contentArr[i].videoEffects[j].videoUrl;

                        this.contentArr[i].videoEffects[j].videoEl.appendChild(sourceAndroid)
                        this.contentArr[i].videoEffects[j].videoEl.appendChild(sourceIos)

                        appEl.appendChild(this.contentArr[i].videoEffects[j].videoEl);

                        var textureEffect = new THREE.VideoTexture( this.contentArr[i].videoEffects[j].videoEl );
                        textureEffect.encoding = THREE.sRGBEncoding;
                        textureEffect.format = THREE.RGBAFormat;

                        let opacity = 1;
                        let pathArr = this.contentArr[i].videoEffects[j].videoUrl.split("/");
                        let ext = pathArr[pathArr.length - 1].split(".")[1]
                        if(ext == "webm" || ext == "mov"){
                            opacity = 0.2;
                        }

                        if(this.contentArr[i].videoEffects[j].opacity != null){
                            opacity = this.contentArr[i].videoEffects[j].opacity;
                        }

                        var matEffect = new THREE.MeshLambertMaterial({map: textureEffect, opacity: opacity, transparent: true});
                        var planeGeomEffect = new THREE.PlaneGeometry(1,1,1,1);
                        var planeEffect = new THREE.Mesh(planeGeomEffect, matEffect);
                        planeEffect.name = this.contentArr[i].name + "_Effect_" + j;

                        this.contentArr[i].videoEffects[j].effect = planeEffect;

                        if(this.contentArr[i].videoEffects.addToModel == null){
                            this.root.add(planeEffect);
                        }
                    }
                }

                if(this.contentArr[i].buttonLinks != null){
                   this.canvas.addEventListener('touchstart', this.onTouchEvent);
                }
            }
        },
        async dynamicContentLoading(){
            console.log("======================================"+this.contentArr[0]);
            let tempUrls = [];
            this.searchingBool = true;
            if(this.loadType == "col"){
                this.selectExperience = !this.selectExperience;
            }
            
            if(this.contentArr.length > 1){
                this.searchingIcon = "./Ui_Assets/searchingMulti.svg";
            }else{
                this.searchingIcon = this.contentArr[0].srcImage;
            }
            this.preProcessContent();
            this.setupMedia();
            await this.loadGLTF();
            this.calibrateModels();

            for(let index in this.contentArr){
                tempUrls.push(this.contentArr[index].markerUrl);
            }
            this.worker.postMessage({ type: "loadMarkers", markerUrls: tempUrls});

        },
        // SPECIAL FUNCTION FOR ADDING THE VIDEO EFFECTS DIRECTLY TO THE MODELS(modified version of snippet inside preProcessContent)
        specialVideoEffects(i, j, node){
            let appEl = document.getElementById("app");
            this.contentArr[i].videoEffects[j].videoEl = document.createElement('video');

            this.contentArr[i].videoEffects[j].videoEl.WebKitPlaysInline = true;
            this.contentArr[i].videoEffects[j].videoEl.style.display = "none";
            this.contentArr[i].videoEffects[j].videoEl.crossOrigin = 'anonymous';
            this.contentArr[i].videoEffects[j].videoEl.loop = true;
            this.contentArr[i].videoEffects[j].videoEl.setAttribute("playsinline", null)
            if(this.isIOS()){
                this.contentArr[i].videoEffects[j].videoEl.muted = true;
            }
            let sourceAndroid = document.createElement('source');
            let sourceIos = document.createElement('source');

            sourceIos.src = this.contentArr[i].videoEffects[j].safariUrl;
            sourceAndroid.src = this.contentArr[i].videoEffects[j].videoUrl;

            this.contentArr[i].videoEffects[j].videoEl.appendChild(sourceAndroid)
            this.contentArr[i].videoEffects[j].videoEl.appendChild(sourceIos)

            appEl.appendChild(this.contentArr[i].videoEffects[j].videoEl);

            var textureEffect = new THREE.VideoTexture( this.contentArr[i].videoEffects[j].videoEl );
            textureEffect.encoding = THREE.sRGBEncoding;
            textureEffect.format = THREE.RGBAFormat;
            textureEffect.wrapS = THREE.RepeatWrapping; textureEffect.repeat.z = - 1;
            textureEffect.flipY = false;

            let opacity = 1;
            let pathArr = this.contentArr[i].videoEffects[j].videoUrl.split("/");
            let ext = pathArr[pathArr.length - 1].split(".")[1]
            if(ext == "webm" || ext == "mov"){
                opacity = 0.2;
            }

            if(this.contentArr[i].videoEffects[j].opacity != null){
                opacity = this.contentArr[i].videoEffects[j].opacity;
            }

            var matEffect = new THREE.MeshLambertMaterial({map: textureEffect, opacity: opacity, transparent: true});

            node.material = matEffect;
            node.material.needsUpdate = true;

            if(this.contentArr[i].videoEffects[j].scale != null){
                node.scale.copy(new THREE.Vector3().fromArray(this.contentArr[i].videoEffects[j].scale))
            }
        },
        // ----------- LOOP FUNCTIONS ----------- 
        // Send image data to jsartoolkit5 to process
        process() {
            this.context_process.fillStyle = "black";
            this.context_process.fillRect(0, 0, this.pw, this.ph);
            this.context_process.drawImage(this.video, 0, 0, this.vw, this.vh, this.ox, this.oy, this.w, this.h);
            
            var imageData = this.context_process.getImageData(0, 0, this.pw, this.ph);
            this.worker.postMessage({ type: "process", imagedata: imageData }, [imageData.data.buffer]);
        }, 
        // Animation Frame
        tick() {
            if(this.enableRun){
                this.draw();
                requestAnimationFrame(this.tick);  
            }
        },
        // Update content, apply filters and render Three.js scene
        draw() {
            var now = Date.now();
            var dt = now - this.lasttime;
            this.time += dt;
            this.lasttime = now;
            let delta = this.clock.getDelta();
            
            if (!this.world) {
                this.hideContent();
                this.updatedLights = false;
            } else {
                this.showContent(delta);

                // ------------ ONE EURO FILTER  ---------------
                let rootMatrix = this.setMatrix(this.root.matrix, this.world);

                let scale = new THREE.Vector3();
                let pos = new THREE.Vector3();
                let rot = new THREE.Quaternion();
                
                rootMatrix.decompose(pos, rot, scale);
                   
                this.positionFilter.UpdateParams(this.filterOpts.freq, this.filterOpts.mincut, this.filterOpts.beta, this.filterOpts.dcut);

                this.positionXFilter.UpdateParams(this.filterXOpts.freq, this.filterXOpts.mincut, this.filterXOpts.beta, this.filterXOpts.dcut);
                this.positionYFilter.UpdateParams(this.filterYOpts.freq, this.filterYOpts.mincut, this.filterYOpts.beta, this.filterYOpts.dcut);
                this.positionZFilter.UpdateParams(this.filterZOpts.freq, this.filterZOpts.mincut, this.filterZOpts.beta, this.filterZOpts.dcut);
                this.rotationFilter.UpdateParams(this.filter_rot_opts.freq, this.filter_rot_opts.mincut, this.filter_rot_opts.beta, this.filter_rot_opts.dcut);

                let filtered_posX = this.positionYFilter.Filter(new THREE.Vector3(pos.x,0,0));
                let filtered_posY = this.positionYFilter.Filter(new THREE.Vector3(0,pos.y,0));
                let filtered_posZ = this.positionZFilter.Filter(new THREE.Vector3(0,0,pos.z));
                let new_pos = new THREE.Vector3(filtered_posX.x,  filtered_posY.y, filtered_posZ.z)
                let filtered_pos = this.positionFilter.Filter(new_pos);

                let filtered_rot = this.rotationFilter.Filter(new THREE.Euler().setFromQuaternion(rot));
                let euler = new THREE.Euler().setFromVector3(filtered_rot);
                let quat = new THREE.Quaternion().setFromEuler(euler);

                let filtered_matrix = new THREE.Matrix4();
                // filtered_matrix.compose(new THREE.Vector3(filtered_posX.x,0,-1100), new THREE.Quaternion(), scale)  // x test
                // filtered_matrix.compose(new THREE.Vector3(0,filtered_posY.y, -1100), new THREE.Quaternion(), scale)  // y test
                // filtered_matrix.compose(new THREE.Vector3(0,0,filtered_posZ.z), new THREE.Quaternion(), scale)  // z test
                // filtered_matrix.compose(new THREE.Vector3(0,0,-1100), quat, scale) // rotation test 
                // filtered_matrix.compose(filtered_pos, new THREE.Quaternion(), scale)  // possition test
                filtered_matrix.compose(filtered_pos, quat, scale)  // all test
                this.root.matrix = filtered_matrix;
            }

            if(!this.recordVideo){
                if(this.contentArr[this.index] != undefined && this.contentArr[this.index].soundEl != null){
                    let audioTime = this.contentArr[this.index].soundEl.currentTime;
                    this.contentArr[this.index].recordingAudio.currentTime = audioTime;
                }
                // this.recordContext.clearRect(0, 0, this.recordCanvas.width, this.recordCanvas.height);

                let scale = Math.max(this.recordCanvas.width / this.input_width, this.recordCanvas.height / this.input_height);
                let x = (this.recordCanvas.width / 2) - (this.input_width / 2) * scale;
                let y = (this.recordCanvas.height / 2) - (this.input_height / 2) * scale;
                this.recordContext.drawImage(this.video, x, y, this.input_width * scale, this.input_height * scale);

                scale = Math.max(this.recordCanvas.width / window.innerWidth, this.recordCanvas.height / window.innerHeight);
                x = (this.recordCanvas.width / 2) - (window.innerWidth / 2) * scale;
                y = (this.recordCanvas.height / 2) - (window.innerHeight / 2) * scale;
                this.recordContext.drawImage(this.canvas, x, y, window.innerWidth * scale, window.innerHeight * scale);

            }
            
            this.renderer.render(this.scene, this.camera);
        },
        // Set variables with results from jsartoolkit5
        found(msg) {
            if (!msg) {
                this.world = null;
            } else {
                this.world = JSON.parse(msg.matrixGL_RH);
                this.proj = JSON.parse(msg.proj);
                this.index = JSON.parse(msg.index);
            }
        },
        // ----------- VIDEO/IMAGE CAPTURE FUNCTIONS ----------- 
        captureImage(){
            const tempCanvas = document.createElement('canvas');
            document.body.appendChild(tempCanvas);
            tempCanvas.style.display = 'none';
            const dpi = window.devicePixelRatio;
            const width = window.innerWidth; 
            const height = window.innerHeight;
            tempCanvas.width = width * window.devicePixelRatio;
            tempCanvas.height = height * window.devicePixelRatio;
            tempCanvas.style.width = getComputedStyle(tempCanvas).getPropertyValue('width').slice(0,-2) * dpi;
            tempCanvas.style.height = getComputedStyle(tempCanvas).getPropertyValue('height').slice(0,-2) * dpi;

            const tempCtx = tempCanvas.getContext('2d', {preserveDrawingBuffer: true});

            let scale = Math.max(tempCanvas.width / this.input_width, tempCanvas.height / this.input_height);
            let x = (tempCanvas.width / 2) - (this.input_width / 2) * scale;
            let y = (tempCanvas.height / 2) - (this.input_height / 2) * scale;
            tempCtx.drawImage(this.video, x, y, this.input_width * scale, this.input_height * scale);

            scale = Math.max(tempCanvas.width / window.innerWidth, tempCanvas.height / window.innerHeight);
            x = (tempCanvas.width / 2) - (window.innerWidth / 2) * scale;
            y = (tempCanvas.height / 2) - (window.innerHeight / 2) * scale;
            tempCtx.drawImage(this.canvas, x, y, window.innerWidth * scale, window.innerHeight * scale);

            const today = new Date();
            const date = `${today.getDate()}`;
            const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

            this.capturedContent = true;
            this.getCanvasBlob(tempCanvas).then(blob => {
                let tempContentAux = {
                    url: null,
                    time: null,
                    type: null,
                    ios: this.isIOS()
                }
                
                tempContentAux.url = URL.createObjectURL(blob);
                tempContentAux.time = `awwMoment-${date}-${time}.png`;
                tempContentAux.type = "image";

                this.tempContent = tempContentAux;
            })
            .catch(err => console.log(err));  
        },
        getCanvasBlob(canvas) {
            return new Promise(function(resolve) {
                canvas.toBlob(function(blob) {
                    resolve(blob)
                })
            })
        },
        open3dBtnLink(){           
                   window.open(this.modelbtnlink);            
        },
        closePopup(){
            document.getElementById("popupbox").style.display = 'none';
        },
        captureVideo(){
            if(this.recordVideo){
                this.setupCaptureVideo();
                this.recordVideo = false;
            }else{
                this.capturedContent = true;
                // let _this = this;
                this.stopRecording()
                .then (recordedChunks => {
                    let recordedBlob = new Blob(recordedChunks, { type: "video/mp4" });

                    const today = new Date();
                    const date = `${today.getDate()}`;
                    const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;

                    let tempContentAux = {
                        url: null,
                        time: null,
                        type: null,
                        ios: this.isIOS()
                    }
                    tempContentAux.url = URL.createObjectURL(recordedBlob);
                    tempContentAux.time = `awwMoment-${date}-${time}.mp4`;
                    tempContentAux.type = "video";

                    this.tempContent = tempContentAux;
                    // this.capturedContent = true;
                    // this.mediaAudioSource.disconnect(this.tempDest);

                    this.recordingData = [];

                    console.log("Successfully recorded " + recordedBlob.size + " bytes of " + recordedBlob.type + " media.");
                })
                .catch(err => console.log(err));  

                this.recordVideo = true;
            }
        },
        setupCaptureVideo(){
            this.mediaStream.then(() => this.startRecording(this.recordCanvas.captureStream()))
            .catch(err => console.log(err));    
        },
        startRecording(streamCanvas) {
            var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

            if(this.contentArr[0].soundEl != null && !this.isIOS()){
                let soundEl = this.contentArr[0].soundEl; 
               
                // this.tempDest = this.audioCtx.createMediaStreamDestination();
                
                // this.mediaAudioSource = this.audioCtx.createMediaElementSource(soundEl);
                // // Connect streams to the destination audio stream.
                // this.tempAudioSource = this.mediaAudioSource.connect(this.tempDest);

                // // const videoTrack = streamVideo.getTracks()[0];
                // var canvasTrack = streamCanvas.getTracks()[0];
                // var mixedTracks = this.tempDest.stream.getTracks()[0];

                var mixedTracks = null;

                if(isSafari){
                    mixedTracks = soundEl.audioTracks[0];
                }else{
                    let streamSound = soundEl.captureStream();
                    const ctx = new AudioContext();
                    const dest = ctx.createMediaStreamDestination();
                    ctx.createMediaStreamSource(streamSound).connect(dest);

                    mixedTracks = dest.stream.getTracks()[0];
                }

                var canvasTrack = streamCanvas.getTracks()[0];

                var stream = new MediaStream([canvasTrack]);
                // Combine video and audio tracks into single stream.
                if(soundEl.currentTime != 0  && !soundEl.paused){
                    if(mixedTracks != null){
                        stream = new MediaStream([canvasTrack, mixedTracks]);
                    }
                }

                this.recorder = new MediaRecorder(stream);

                this.recorder.ondataavailable = event => this.recordingData.push(event.data);
            }else{
                this.recorder = new MediaRecorder(streamCanvas);

                this.recorder.ondataavailable = event => this.recordingData.push(event.data);
            }         
            this.recorder.start();
        },
        stopRecording() {
            let stopped = new Promise((resolve, reject) => {
                this.recorder.onstop = resolve;
                this.recorder.onerror = event => reject(event.name);
            });

            let recorded = new Promise(resolve => resolve()).then(
                () => this.recorder.state == "recording" && this.recorder.stop()
            );

            return Promise.all([
                stopped,
                recorded
            ])
            .then(() => this.recordingData);
        },
        // ----------- LOAD GLTF FUNCTIONS -----------
        async loadGLTF(){
            for(let i = 0; i < this.contentArr.length; i++){
                if(this.contentArr[i].modelUrl != null){
                    await this.modelLoader(this.contentArr[i].modelUrl).then(data =>{
                        this.contentArr[i].content = data.scene;
                        this.contentArr[i].content.name = this.contentArr[i].name;
                        //transforms
                        // this.contentArr[i].content.position.copy(new THREE.Vector3().fromArray(this.contentArr[i].position));
                        // this.contentArr[i].content.rotation.copy(new THREE.Euler().fromArray(this.contentArr[i].rotation));
                        // this.contentArr[i].content.scale.copy(new THREE.Vector3(1,1,1));
                        if(this.contentArr[i].rotation != null){
                            let x = parseFloat(this.contentArr[i].rotation[0]);
                            let y = parseFloat(this.contentArr[i].rotation[1]);
                            let z = parseFloat(this.contentArr[i].rotation[2]);
                            let rot = new THREE.Euler(x, y, z, "XYZ");
                            this.contentArr[i].content.rotation.copy(rot);
                        }  
                        //animation
                        this.contentArr[i].animations = data.animations;
                        this.contentArr[i].mixer = new THREE.AnimationMixer( data.scene );
                        
                        this.contentArr[i].animations.forEach( ( clip ) => { 
                        this.contentArr[i].mixer.clipAction( clip ).play();  
                        });
                        let _this = this;
                        this.contentArr[i].content.traverse((node) => {
                            if (node.isMesh) {
                                node.frustumCulled = false;
                                // node.material.depthWrite = !node.material.transparent;
                                node.material.side = THREE.DoubleSide;
                                // node.material.metalness = 0.8;
                                node.material.envMap = _this.environmentMaps
                                node.material.needsUpdate = true;
                            }
                        });

                        if(this.contentArr[i].videoEffects != null){
                            for(let j = 0; j < this.contentArr[i].videoEffects.length; j++){
                                if(this.contentArr[i].videoEffects[j].addToModel != null){
                                    this.contentArr[i].content.traverse((node) => {
                                        if (node.isMesh && node.name == "Video_plane_" + this.contentArr[i].videoEffects[j].addToModel) {
                                            this.specialVideoEffects(i, j, node);
                                        }
                                    });
                                }
                            }
                        }

                        if(this.contentArr[i].buttonLinks != null){
                            for(let j = 0; j < this.contentArr[i].buttonLinks.length; j++){
                                this.contentArr[i].content.traverse((node) => {
                                    if (node.isMesh && node.name == "Button_link_" + this.contentArr[i].buttonLinks[j].id) {
                                        this.buttonObjects.push(node);
                                    }
                                });
                            }
                        }

                        this.root.add(this.contentArr[i].content);
                    });
                }
            }
            
            console.log("Finished loading models")
        },
        modelLoader(url) {
            const loader = new GLTFLoader()
            .setDRACOLoader(new DRACOLoader().setDecoderPath( '../wasm/' ))
            .setKTX2Loader(new KTX2Loader()
                .setTranscoderPath( '../wasm/' )
            )
            .setMeshoptDecoder( MeshoptDecoder );

            return new Promise((resolve, reject) => {
                loader.load(url, data=> resolve(data), null, reject);
            });
        },
        // ----------- UTIL FUNCTIONS ----------- 
        isMobile() {
            return /Android|mobile|iPad|iPhone/i.test(navigator.userAgent);
        },
        setMatrix(matrix, value) {
            var array = [];
            for (var key in value) {
                array[key] = value[key];
            }
            if (typeof matrix.elements.set === "function") {
                matrix.elements.set(array);
                return matrix;
            } else {
                matrix.elements = [].slice.call(array);
                return matrix;
            }
        },
        hideContent(){
            this.spotLightTop.visible = true;
            this.spotLightLeft.visible = true;
            this.spotLightRightAngled.visible = true;

            for(let indexTemp in this.contentArr){
                if(this.contentArr[indexTemp].content != null){
                    this.contentArr[indexTemp].content.visible = false;
                }
                
                this.showLinkModal = false;

                if(this.contentArr[indexTemp].videoEl != null){
                    this.mainVideoEl = this.contentArr[indexTemp].videoEl;
                    this.contentArr[indexTemp].videoEl.pause();
                }else if(this.contentArr[indexTemp].soundEl != null){
                    this.soundEl = this.contentArr[indexTemp].soundEl;
                    this.recordingSoundEl = this.contentArr[indexTemp].recordingAudio;
                    this.$refs["SoundControl"].pauseAudio();
                    // this.contentArr[indexTemp].recordingAudio.pause();
                }

                if(this.contentArr[indexTemp].videoEffects != null){
                    for(let j = 0; j < this.contentArr[indexTemp].videoEffects.length; j++){
                        this.contentArr[indexTemp].videoEffects[j].effect.visible = false;
                        this.contentArr[indexTemp].videoEffects[j].videoEl.pause();
                    }
                }
            }
        },
        showContent(delta){
            if(this.contentArr[this.index].content != null){
                this.contentArr[this.index].content.visible = true;
            }

            if( this.contentArr[this.index].animations != null){
                this.contentArr[this.index].animations.forEach( ( clip ) => { 
                    this.contentArr[this.index].mixer.clipAction( clip ).play();
                });
                this.contentArr[this.index].mixer.update( delta )
            }

            if(this.contentArr[this.index].videoEl != null){
                this.mainVideoEl = this.contentArr[this.index].videoEl;
                this.contentArr[this.index].videoEl.play();

                this.spotLightTop.visible = false;
                this.spotLightLeft.visible = false;
                this.spotLightRightAngled.visible = false;
            }

            if(this.contentArr[this.index].videoEffects != null){
                this.spotLightTop.visible = false;
                this.spotLightLeft.visible = false;
                this.spotLightRightAngled.visible = false;

                for(let j = 0; j < this.contentArr[this.index].videoEffects.length; j++){
                    this.contentArr[this.index].videoEffects[j].effect.visible = true;
                    this.contentArr[this.index].videoEffects[j].videoEl.play();
                }
            }

            if(this.contentArr[this.index].soundEl != null){
                this.soundEl = this.contentArr[this.index].soundEl;
                this.recordingSoundEl = this.contentArr[this.index].recordingAudio;
                // this.contentArr[this.index].recordingAudio.play();
                this.$refs["SoundControl"].playAudio();
            }

            if(this.contentArr[this.index].linkUrl != null){
                this.linkUrl = this.contentArr[this.index].linkUrl;
                this.showLinkModal = true;
                // this.contentArr[this.index].content.click();
            }
        },
        isIOS() {
            return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        },
        workUtil(el){
            el.play().then(()=>el.pause()); 
        },
        // ----------- CLOSE JSARTOOLKIT5 APP  ----------- 
        close(){
            this.enableRun = false;
            this.searchingBool = false;
            this.hideContent();

            let appEl = document.getElementById("app");
            for(let index in this.contentArr){
                if(this.contentArr[index].videoEl != null){
                    appEl.removeChild(this.contentArr[index].videoEl);
                }

                if(this.contentArr[index].videoEffects != null){
                    for(let j in this.contentArr[index].videoEffects){
                        appEl.removeChild(this.contentArr[index].videoEffects[j].videoEl);
                    }
                }
                
            }
            
            this.scene.remove(this.root);
            this.root = new THREE.Object3D();
            this.root.matrixAutoUpdate = false;
            this.scene.add(this.root);

            this.renderer.render(this.scene, this.camera);

            this.setupLights();
            
            this.worker.postMessage({type: "resetMarkers"});
            
            // this.$emit("close")
            this.$root.$emit("returnToExp")
        },
        // ----------- MISC  ----------- 
        onWindowResize(){
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize( window.innerWidth, window.innerHeight );
            // this.canvas_process.width = window.innerWidth;
            // this.canvas_process.height = window.innerHeight;
        },
        changeSelect(e){
            this.selectOption = e.target.value;
        },
        setupMedia(){
            // if(this.isIOS()){ // IOS WORKAROUND PLAYING VIDEOS AND SOUNDS
                
                for(let index in this.contentArr){
                    if(this.contentArr[index].soundUrl != null){
                        // this.workUtil(this.contentArr[index].soundEl)
                        this.contentArr[index].soundEl.muted = false;
                        this.contentArr[index].recordingAudio.muted = false;
                        // alert("IOS LOADED AUDIO")
                    }
                }
            //         if(this.contentArr[index].videoUrl != null){
            //             this.workUtil(this.contentArr[index].videoEl)
            //             this.contentArr[index].videoEl.muted = false;
            //             // alert("IOS LOADED VIDEO")
            //         }

            //         if(this.contentArr[index].videoEffects != null){
            //             for(let j in this.contentArr[index].videoEffects){
            //                 this.workUtil(this.contentArr[index].videoEffects[j].videoEl);
            //             }
            //             // alert("IOS LOADED EFFECTS")
            //         }
            //     }

            // }
        },
        // EVENTS
        onTouchEvent(e){
            // e.preventDefault();
            let touch = e.touches[0];
            this.mouse.x = (touch.pageX / window.innerWidth ) * 2 - 1;
            this.mouse.y = - (touch.pageY / window.innerHeight ) * 2 + 1; 

            this.raycaster.setFromCamera(this.mouse, this.camera);

            let intersects = this.raycaster.intersectObjects(this.buttonObjects, true);
            console.log(e)
            if(intersects.length > 0 && this.index != null){
                let obj = intersects[0].object;
                let str = obj.name.split("_");
                for(let j = 0; j < this.contentArr[this.index].buttonLinks.length; j++){
                    if(this.contentArr[this.index].buttonLinks[j].id == str[2]){
                       var openwindow= window.open(this.contentArr[this.index].buttonLinks[j].link, "_blank"); 
                       this.modelbtnlink =    this.contentArr[this.index].buttonLinks[j].link;               
                        if(!openwindow){
                           setTimeout(() => openwindow, 1000);
                            if(!openwindow){
                                 document.getElementById("popupbox").style.display = 'block'; 
                            }
                        }                               
                    }
                }
            }
        }
    },
    mounted(){
        if(this.loadType == "qr"){
            this.canvas = document.querySelector("#canvas");
            this.video = document.querySelector("#recordVideo");
        }else{
            this.canvas = this.canvasProp;
            this.video = this.videoProp;
            this.mediaStream = this.mediaStreamProp;
            this.selectExperience = true;
        }

        this.worker = new Worker(this.worker_path);
        
        this.input_width = this.video.videoWidth;
        this.input_height = this.video.videoHeight;

        this.canvas_process = document.createElement('canvas');
        this.context_process = this.canvas_process.getContext('2d', {preserveDrawingBuffer: true}); 
        
        this.recordCanvas = document.createElement("canvas");
        this.recordContext = this.recordCanvas.getContext("2d", {preserveDrawingBuffer: true});

        this.$root.$on("captureImage", () =>{
            this.captureImage();
        })
        this.$root.$on("captureVideo", () =>{
            this.captureVideo();
        })
        this.$root.$on("closePreview", () =>{
            this.capturedContent = false;
        })

        window.addEventListener( 'resize', this.onWindowResize, false );

        this.initAR();
    },
    beforeDestroy(){
        this.video.removeEventListener("loadedmetadata", function() {
            this.video.play();
            this.inputwidth = this.video.videoWidth;
            this.inputheight = this.video.videoHeight;
        });

        this.video.removeEventListener("playing", function(){
            this.start();
        });

        window.removeEventListener( 'resize', this.onWindowResize, false );

        this.canvas.removeEventListener('touchstart', this.onTouchEvent);
    },
    watch:{
        contentArr(newVal){
            if(newVal.length != 0){
                // console.log("new", newVal)
                this.dynamicContentLoading();
            }
        },
        marker_found_index(newVal){
            if(newVal != -1){
                this.showContent()
            }else{
                this.hideContent();
            }
        }
    }
}
</script>
<style scoped>
    #ar_container{
        overflow: hidden;
    }
    #video {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }
    #recordVideo {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }
    .canvas {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 100;
        display: block;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
    }
    #enableButton{
        position: absolute;
        z-index: 500;
    }
    .searchingIcon{
        display: none;
    }
    .recordButton{
        position: fixed;
        z-index: 600;
    }
    #close_bt{
        position: absolute;
        left: 15px;
        top: 25px;
        z-index: 10000;
        width: 80px;
        height: auto;
        /* border-style: solid;
        border-radius: 5px;
        border-color: red;
        background-color: rgb(255, 100, 100);
        font-weight: bold;
        font-size: 20px;
        color: white; */
    }
    .link_popup{
        position: absolute;
        z-index: 10000;
    }
    #filter_select{
        position: absolute;
        z-index: 10000;
        left: 50vw;
    }
    #topborder{
        z-index: 10000;
        position: absolute;
        top: 0;
        left: 0;
        height: 80px;
    }
    #popupbox{
        z-index: 10000;
        position: absolute;
        height: 100px;
        border:2px solid black;
        border-radius: 5px;
        background: white;
        width:50vw;
        margin:auto;
        left:0;right:0;
        display:none;
    } 
    #popupbox button{
        padding:4px;
        margin:4px;
        background:#3982f7;
        color:white;
        border-radius: 3px;
    }   
    #logo{
        z-index: 10000;
        position: absolute;
        top: 4px;
        left: 43vw;
        height: 55px;
    }
</style>