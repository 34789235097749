import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './gtm.js'
import VueGtag from "vue-gtag";

// import adapter from 'webrtc-adapter';
// console.log("using adapter", adapter)
Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: "UA-204958587-2" }
});


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


